import { Avatar, Badge, Menu, Popconfirm, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './sidebar.less';

// SIDEBAR LOGO AS REACT COMPONENT (SVG)
import { BsCollection } from 'react-icons/bs';
import { ReactComponent as LogoutBtnIcon } from '../../assets/other-icons/logout-btn.svg';
import { ReactComponent as CategoriesLogo } from '../../assets/sidebar-icons/categories.svg';
import { ReactComponent as FeaturedProductLogo } from '../../assets/sidebar-icons/featured.svg';
import { ReactComponent as OrderLogo } from '../../assets/sidebar-icons/orders.svg';
import { signOutAdmin } from '../../modules/auth/auth-repository';
import { getActiveData } from '../core-repository';

export function ManagerSIdeBar({ user }) {
  const navigate = useNavigate();

  // STATES
  const [activeOrderRequests, setActiveOrderRequests] = useState(0);

  let intervalId;
  const fetchLiveData = async () => {
    try {
      const response = await getActiveData();
      setActiveOrderRequests(response.totalPendingOrders);
    } catch (error) {
      //
    }
  };
  useEffect(() => {
    fetchLiveData();
    if (intervalId) {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalId = setInterval(() => {
      fetchLiveData();
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-sidebar">
      <div className="sidebar-logo">
        <Typography.Title>A1 ADMIN</Typography.Title>
      </div>
      <Menu
        className="sidebar-menu"
        onClick={(item) => {
          navigate(item.key);
        }}
        // eslint-disable-next-line no-undef
        defaultSelectedKeys={[window.location.pathname]}
        // eslint-disable-next-line no-undef
        selectedKeys={[window.location.pathname]}
        mode="inline"
        items={[
          {
            label: (
              <span>
                Orders{' '}
                <Badge
                  className="badge"
                  count={activeOrderRequests}
                  size="small"
                />
              </span>
            ),
            key: '/',
            icon: <OrderLogo />
          },

          {
            label: 'Categories',
            key: '/categories',
            icon: <CategoriesLogo />
          },
          {
            label: 'All Products',
            key: '/all-products',
            icon: <BsCollection size={23} />
          },
          {
            label: 'Featured Products',
            key: '/featured-products',
            icon: <FeaturedProductLogo />
          }
        ]}
      />
      <div className="profile-content-wrapper">
        <div className="profile-text">PROFILE</div>
        <div className="partation">
          <Space size={10} className="profile-content">
            <Avatar size={40} />
            <div className="name-info">
              <Typography.Text>{user.name}</Typography.Text>
              <Typography.Text className="email-text">
                {user.email}
              </Typography.Text>
            </div>
          </Space>
          <Popconfirm
            title="Logout"
            description="Are you sure to logout from system?"
            onConfirm={async () => {
              await signOutAdmin();
              // eslint-disable-next-line no-restricted-globals, no-undef
              location.href = '/';
            }}
            okText="Yes"
            cancelText="No"
          >
            <span className="logout-icon">
              <LogoutBtnIcon />
            </span>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}
