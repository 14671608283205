import client from '../../apollo';
import { APPROVE_ORDER, CHANGE_STATUS, REJECT_ORDER } from './graphql/mutation';
import {
  GET_ORDERS_REQUESTS,
  GET_ORDER_BY_ID,
  GET_PENDING_COUNT
} from './graphql/query';

export const getOrders = async (filters, orderType) => {
  const { skip, limit, query, type, dateFilter } = filters;
  // const { startAt, endAt } = dateFilter;
  const variables = {
    filters: {
      skip,
      limit,
      query,
      type
    },
    orderType
  };
  if (dateFilter) variables.dateFilter = dateFilter;
  try {
    const response = await client.query({
      query: GET_ORDERS_REQUESTS,
      fetchPolicy: 'network-only',
      variables
    });
    return response?.data?.getOrders;
  } catch (error) {
    return [];
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await client.query({
      query: GET_ORDER_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data?.getOrderById;
  } catch (error) {
    return [];
  }
};

export const changeActiveOrderStatus = async (
  orderStatus,
  orderId,
  optionalData
) => {
  try {
    const response = await client.mutate({
      mutation: CHANGE_STATUS,
      fetchPolicy: 'network-only',
      variables: { orderStatus, orderId, optionalData }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const getOrdersCount = async (filters, orderType) => {
  const { skip, limit, query, type } = filters;
  try {
    const response = await client.query({
      query: GET_PENDING_COUNT,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          type
        },
        orderType
      }
    });
    return response?.data?.getOrders;
  } catch (error) {
    return [];
  }
};

export const approveOrder = async (data) => {
  try {
    const response = await client.mutate({
      mutation: APPROVE_ORDER,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const rejectOrder = async (data) => {
  try {
    const response = await client.mutate({
      mutation: REJECT_ORDER,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
