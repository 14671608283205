import { gql } from '@apollo/client';

export const GET_ATTRIBUTES = gql`
  query getAllAttributes($filters: FilterDataInput) {
    getAllAttributes(filters: $filters) {
      attributes {
        id
        name
        description
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
