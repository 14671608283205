const convertMinutesToHHmmA = (t) => {
  const hours = Math.floor(t / 60);
  const minutes = t % 60;
  let response = '';
  if (hours > 12) {
    const updatedHr = 12 - hours;
    response = `${Math.abs(updatedHr).toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} pm`;
  } else {
    response = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} am`;
  }
  return response;
};

const convertHHmmAToMinutes = (timeSlot) => {
  const splittedWithSpace = timeSlot.split(' ');
  const amPm = splittedWithSpace[1];
  const splitWithColon = splittedWithSpace[0].split(':');
  let hr = parseInt(splitWithColon[0], 10);
  const min = parseInt(splitWithColon[1], 10);
  if (amPm === 'pm') {
    hr += 12;
  }
  return hr * 60 + min;
};

function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
module.exports = {
  sleep
};

module.exports = { convertMinutesToHHmmA, convertHHmmAToMinutes, sleep };
