import { gql } from '@apollo/client';

export const DELETE_FEATURE_PRODUCT = gql`
  mutation removeFeatureProduct($productId: String!) {
    removeFeatureProduct(productId: $productId)
  }
`;

export const ADD_FEATURE_PRODUCT = gql`
  mutation addFeatureProduct($productId: String!) {
    addFeatureProduct(productId: $productId)
  }
`;

export const REARRANGE_FEATURE_PRODUCTS = gql`
  mutation rearrangeFeatureProduct($productsId: [String!]!) {
    rearrangeFeatureProduct(productsId: $productsId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
