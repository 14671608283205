/* eslint-disable no-console */
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Typography,
  Upload,
  message
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SearchComponent from '../../../../common/SearchDebounce';
import CommonTable from '../../../../common/components/CommonTable';
import './products.less';

import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as DeleteBtn } from '../../../../assets/other-icons/deletebtn.svg';
import { ReactComponent as EditIcon } from '../../../../assets/other-icons/edit.svg';
import { getFileSignedUrl } from '../../../../common/common-repository';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import {
  createProduct,
  deleteProduct,
  getAllAttributes,
  getProducts,
  toggleDisableProduct,
  uploadProductImage
} from '../../categories-repository';

export function Products({ user }) {
  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  const { sub_cat_id: subCatId } = useParams();
  const navigate = useNavigate();
  const [createForm] = Form.useForm();
  const { required } = formValidatorRules;
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [products, setProducts] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [paginationProp, setPaginationProp] = useState();
  const [isCreateModal, setIsCreateModal] = useState(false);
  // const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const fetchProducts = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getProducts(
        {
          skip: currentSkip,
          limit: limit,
          query: currentQuery
        },
        subCatId
      );
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setProducts(response.products.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchProducts({
      currentQuery: '',
      currentSkip: 0
    });
    getAllAttributes().then((response) => {
      const listOptions = [];
      for (let i = 0; i < response.attributes.length; i += 1) {
        listOptions.push({
          label: response.attributes[i].name,
          value: response.attributes[i].id
        });
      }

      setAttributes(listOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    createForm.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsCreateModal(false);
  };

  const deleteSelectedProduct = async (index, ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting product'
    });
    await deleteProduct(ID);
    const delProduct = products.slice();
    delProduct.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    setProducts(delProduct);
  };

  const toggleEnableDisable = async (index, record) => {
    if (record.isDefault) {
      message.error("DEFAULT PRODUCT CAN'T BE DISABLED");
      return;
    }
    products[index] = {
      ...products[index],
      isDisable: !(products[index].isDisable || false)
    };
    await toggleDisableProduct(products[index].id);
    setProducts(products.slice());
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'ATTRIBUTE',
      dataIndex: 'name',
      key: '3',
      render: (value, record) => {
        return record?.attribute?.name;
      }
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: '4'
    },

    {
      title: '',
      dataIndex: 'name',
      key: '8',
      render: (value, record, index) => {
        if (isManager) {
          return (
            <div className="btns-section">
              <EditIcon
                className="edit-icon"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals, no-undef
                  const url = `${location.pathname}/${record.id}`;
                  navigate(url);
                }}
              />
            </div>
          );
        }
        return (
          <div className="btns-section">
            <EditIcon
              className="edit-icon"
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals, no-undef
                const url = `${location.pathname}/${record.id}`;
                navigate(url);
              }}
            />
            <Popconfirm
              title="DELETE PRODUCT "
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                    width: '10px'
                  }}
                />
              }
              description="ARE YOU SURE YOU WANT TO DELETE THIS PRODUCT"
              onConfirm={() => deleteSelectedProduct(index, record.id)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
          </div>
        );
      }
    },
    !isManager
      ? {
          title: 'ACTION ',
          dataIndex: 'id',
          key: '9',
          render: (value, record, index) => {
            if (record.isDisable) {
              return (
                <Button
                  onClick={() => toggleEnableDisable(index, record)}
                  type="text"
                >
                  DISABLED
                </Button>
              );
            }
            return (
              <Popconfirm
                title="DISABLE PRODUCT"
                description="ARE YOU SURE YOU WANT TO DISABLE THIS PRODUCT"
                onConfirm={() => toggleEnableDisable(index, record)}
                okText="YES"
                cancelText="NO"
              >
                <Button type="text">ENABLED</Button>
              </Popconfirm>
            );
          }
        }
      : // Empty object when isManager is false
        {}
    // {
    //   title: 'ACTION ',
    //   dataIndex: 'id',
    //   key: '9',
    //   render: (value, record, index) => {
    //     if (record.isDisable) {
    //       return (
    //         <Button
    //           onClick={() => toggleEnableDisable(index, record)}
    //           type="text"
    //         >
    //           DISABLED
    //         </Button>
    //       );
    //     }
    //     return (
    //       <Popconfirm
    //         title="DISABLE PRODUCT"
    //         description="ARE YOU SURE YOU WANT TO DISABLE THIS PRODUCT"
    //         onConfirm={() => toggleEnableDisable(index, record)}
    //         okText="YES"
    //         cancelText="NO"
    //       >
    //         <Button type="text">ENABLED</Button>
    //       </Popconfirm>
    //     );
    //   }
    // }
  ];

  const submitCreateForm = async (formValue) => {
    const { name, description, attribute } = formValue;
    if (!uploadedImage) {
      message.error('Image is required');
      return;
    }
    setIsSavingData(true);
    const createNewSubCategory = await createProduct(
      {
        name,
        description,
        attribute,
        image: uploadedImage
      },
      subCatId
    );
    if (
      products.length === 0 ||
      products.length < limit ||
      products[products.length - 1].serialNumber + 1 ===
        createNewSubCategory.serialNumber
    ) {
      const updatedSubCategories = products.slice();
      updatedSubCategories.push(createNewSubCategory);
      setProducts(updatedSubCategories.slice());
    }

    createForm.resetFields();
    setUploadedImage(undefined);
    setIsSavingData(false);
    setIsCreateModal(false);
    fetchProducts({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
    const newID = createNewSubCategory.id;
    // eslint-disable-next-line no-restricted-globals, no-undef
    const url = `${location.pathname}/${newID}`;
    navigate(url);
  };

  const beforeUpload = (file) => {
    const isLT1MB = file.size / 1024 / 1024 <= 1;
    if (!isLT1MB) {
      message.error(`File must smaller than 1MB!`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await uploadProductImage(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const rearrangeDirect = () => {
    // eslint-disable-next-line no-restricted-globals, no-undef
    const url = `${location.pathname}/rearrange`;
    navigate(url);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title="NEW PRODUCT"
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form layout="vertical" form={createForm} onFinish={submitCreateForm}>
            <Form.Item label="Name" name="name" rules={[required]}>
              <Input id="attribute-inp" placeholder="Enter product name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[required]}
            >
              <TextArea
                autoSize={{
                  minRows: 5,
                  maxRows: 5
                }}
                type="text"
                placeholder="Enter description"
              />
            </Form.Item>
            <div className="prod-image-ipload">
              <Upload
                accept="image/*"
                onChange={onIconUpload}
                beforeUpload={beforeUpload}
                showUploadList={false}
              >
                {uploadedImage && !uploadingImage && (
                  <img src={uploadedImage} className="sample-img " alt="xyz" />
                )}
                {!uploadedImage && !uploadingImage && (
                  <p className="upload-banner-text">UPLOAD PRODUCT IMAGE</p>
                )}
                {uploadingImage && <LoadingOutlined />}
              </Upload>
            </div>
            <Form.Item label="Attribute" name="attribute" rules={[required]}>
              <Select
                placeholder="Attribute"
                onChange={() => {}}
                options={attributes}
              />
            </Form.Item>
            <Button loading={isSavingData} id="create-btn" htmlType="submit">
              ADD PRODUCT
            </Button>
          </Form>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn
            className="back-btn"
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="request-header-title">
            CATEGORIES/SUB CATEGORIES/
          </span>
          PRODUCTS
        </Typography.Title>
        <div className="btn-header-section">
          <Button onClick={() => setIsCreateModal(true)}> ADD PRODUCT</Button>
          <Button onClick={rearrangeDirect}>RE-ARRANGE LIST</Button>
        </div>
      </Header>
      <div className="categories-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Product"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchProducts({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>
        </div>
        <div className="table-section categories-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              rowClassName={(record) =>
                record.isDisable ? 'disabled-row' : null
              }
              columns={columns}
              data={products}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchProducts({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
