/* eslint-disable no-console */
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Empty,
  Form,
  InputNumber,
  Modal,
  Row,
  Tag,
  message
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../../../common/components/CommonTable';
// import './active-order.less';

import { ReactComponent as OrderEye } from '../../../../../assets/other-icons/orderEye.svg';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import {
  approveOrder,
  getOrderById,
  getOrders,
  rejectOrder
} from '../../../orders-repository';

export function PendingOrders({ query }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [requestOrderForm] = Form.useForm();
  const { required } = formValidatorRules;
  // STATES
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [orderID, setOrderID] = useState('');
  const [selectedOrderID, setSelectedOrderID] = useState('');
  const [, setCurrentGlobalSkip] = useState(0);
  const [activeRequests, setActiveRequests] = useState([]);
  const [singleOrder, setSingleOrder] = useState([]);
  const [reqProducts, setReqProducts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [paginationProp, setPaginationProp] = useState();
  const [totalValue, setTotalValue] = useState();

  const fetchActiveOrders = async ({ currentSkip }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOrders(
        {
          skip: currentSkip,
          limit: limit,
          query: query
        },
        'PENDING'
      );

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveRequests(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchActiveOrders({
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const openOrderModalData = async (ID) => {
    setLoadingData(true);
    const response = await getOrderById(ID);
    setSingleOrder(response.order);
    setReqProducts(response?.order?.orderSummary?.products.slice());
    setTotalValue(response.order.isPaid);
    setIsCreateModal(true);
    setLoadingData(false);
  };

  const handleCancelCreate = () => {
    setIsCreateModal(false);
    setTotalValue();
    setReqProducts([]);
    setShippingCharges(0);
    requestOrderForm.resetFields();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1'
    },
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: '2'
    },
    {
      title: 'RECIPIENT NAME',
      dataIndex: 'serialNumber',
      key: '3',
      render: (value, record) => {
        return record?.distributor?.name;
      }
    },
    {
      title: 'NO. OF ITEMS',
      dataIndex: 'serialNumber',
      key: '4',
      render: (value, record) => {
        return record.items.length;
      }
    },
    {
      title: 'PLACED ON',
      dataIndex: 'createdAt',
      key: '5',
      render: (value) => {
        return (
          <div>
            {moment(value).format('MM/DD/YYYY')} | {moment(value).format('LT')}
          </div>
        );
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'total',
      key: '6',
      render: (value) => {
        return `$ ${value}`;
      }
    },
    {
      title: 'IS PAID',
      dataIndex: 'isPaid',
      key: '7',
      render: (value) => {
        return value ? (
          <Tag className="paid-tag">YES</Tag>
        ) : (
          <Tag className="pending-tag">PENDING</Tag>
        );
      }
    },
    {
      title: '',
      dataIndex: 'orderId',
      key: '9',
      render: (value, record) => {
        return (
          <OrderEye
            onClick={() => {
              setOrderID(value);
              setSelectedOrderID(record.id);
              openOrderModalData(record.id);
            }}
            className="edit-icon "
          />
        );
      }
    }
  ];

  const rejectOrderForm = async (formValue) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });
    requestOrderForm.resetFields();
    const data = {
      orderId: selectedOrderID,
      note: formValue.note
    };
    await rejectOrder(data);
    const newIndex = activeRequests.findIndex(
      (obj) => obj.id === selectedOrderID
    );
    const updatedarr = activeRequests.slice();
    updatedarr.splice(newIndex, 1);
    setActiveRequests(updatedarr);
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
    setIsCreateModal(false);
  };

  const approveOrderForm = async (formValue) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });
    requestOrderForm.resetFields();
    const items = [];
    for (let i = 0; i < reqProducts.length; i += 1) {
      items.push({
        variantId: reqProducts[i].purchasedVariant.variantId,
        qty: reqProducts[i].purchasedVariant.qty
      });
    }
    const data = {
      orderId: selectedOrderID,
      items,
      shippingCharges: parseFloat(shippingCharges),
      note: formValue.note
    };

    await approveOrder(data);
    const foundIndex = activeRequests.findIndex(
      (obj) => obj.id === selectedOrderID
    );
    const updatedarr = activeRequests.slice();
    updatedarr.splice(foundIndex, 1);
    setActiveRequests(updatedarr);
    setShippingCharges(0);
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
    setIsCreateModal(false);
  };

  const submitFormAction = (formValue) => {
    // eslint-disable-next-line no-undef
    const buttonType = window.event.submitter.name;

    if (buttonType === 'approve') {
      approveOrderForm(formValue);
    } else {
      rejectOrderForm(formValue);
    }
  };

  const recalculateTotal = (data) => {
    const { products = reqProducts, currentShippingCharges = shippingCharges } =
      data;
    let total = 0;
    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      total += product.purchasedVariant.qty * product.purchasedVariant.price;
    }
    total += currentShippingCharges;
    setTotalValue(total);
  };

  const minusCount = (index) => {
    if (reqProducts[index].purchasedVariant.qty <= 1) {
      return;
    }
    const newProdVal = reqProducts.slice();
    const element = {
      ...newProdVal[index],
      purchasedVariant: {
        ...newProdVal[index].purchasedVariant,
        qty: newProdVal[index].purchasedVariant.qty - 1
      }
    };
    newProdVal[index] = element;
    recalculateTotal({
      products: newProdVal
    });
    setReqProducts(newProdVal);
  };

  const plusCount = (index) => {
    const newProdVal = reqProducts.slice();
    const element = {
      ...newProdVal[index],
      purchasedVariant: {
        ...newProdVal[index].purchasedVariant,
        qty: newProdVal[index].purchasedVariant.qty + 1
      }
    };
    newProdVal[index] = element;
    recalculateTotal({
      products: newProdVal
    });
    setReqProducts(newProdVal);
  };

  const removeProduct = (index) => {
    const newProds = reqProducts.slice();
    newProds.splice(index, 1);
    recalculateTotal({
      products: newProds
    });
    setReqProducts(newProds);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={orderID}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal active-modal pending-modal"
        centered
      >
        <Divider />
        <div className="modal-content active-content">
          <Row>
            <Col>
              <div className="left">
                <div className="prod-list">
                  {reqProducts.length < 1 ? (
                    <div className="no-data">
                      <Empty />
                    </div>
                  ) : (
                    reqProducts.map((prod, index) => {
                      return (
                        <div key={prod.purchasedVariant.variantId}>
                          <div className="products-section">
                            <div className="product-card">
                              <div className="left-sect">
                                <Avatar
                                  src={prod.purchasedVariant.image}
                                  size={100}
                                  shape="square"
                                />
                              </div>
                              <div className="right-sect">
                                <div className="upper-sect">
                                  <section className="info-sect">
                                    <section className="prod-title">
                                      {prod.title}
                                    </section>
                                    <section className="prod-flavour">
                                      {prod.purchasedVariant.title}
                                    </section>
                                  </section>
                                  <section className="prod-cross-btn">
                                    <CloseCircleOutlined
                                      onClick={() => removeProduct(index)}
                                    />
                                  </section>
                                </div>
                                <div className="lower-sect">
                                  <section className="product-price">
                                    $ {prod.purchasedVariant.price}
                                  </section>
                                  <section>
                                    <div className="counter">
                                      <section
                                        onClick={() => minusCount(index)}
                                        className="minus-part"
                                      >
                                        -
                                      </section>
                                      <section className="num-part">
                                        {prod.purchasedVariant.qty}
                                      </section>
                                      <section
                                        onClick={() => plusCount(index)}
                                        className="plus-part"
                                      >
                                        +
                                      </section>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      );
                    })
                  )}
                </div>
                <Form
                  name="action-form"
                  layout="vertical"
                  form={requestOrderForm}
                  onFinish={submitFormAction}
                >
                  <Form.Item label="Add Note" name="note" rules={[required]}>
                    <TextArea
                      autoSize={{
                        minRows: 4,
                        maxRows: 4
                      }}
                      type="text"
                      placeholder="Enter note"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Shipping Charges"
                    name="scharge"
                    // rules={[required]}
                    initialValue={0}
                  >
                    <InputNumber
                      className="shipping-charges"
                      placeholder="Enter shipping charges"
                      controls={false}
                      onChange={(value) => {
                        recalculateTotal({
                          currentShippingCharges: value
                        });
                        setShippingCharges(value);
                      }}
                    />
                  </Form.Item>
                  <Divider />
                  <div className="total-price-sect">
                    <section>TOTAL</section>
                    <section>
                      ${totalValue || singleOrder?.orderSummary?.total}
                    </section>
                  </div>
                  <Divider />
                  <div className="req-btn-section">
                    <Button
                      name="reject"
                      htmlType="submit"
                      id="order-reject-btn"
                    >
                      REJECT
                    </Button>
                    <Button
                      name="approve"
                      disabled={reqProducts.length === 0}
                      htmlType="submit"
                      id="order-approve-btn"
                    >
                      APPROVE
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col>
              <div className="right ">
                <div className="customer-details">CUSTOMER DETAILS</div>
                <Divider dashed />
                <div className="customer-name">
                  <b> NAME:</b> {singleOrder?.distributor?.name}
                </div>
                <div className="customer-name">
                  <b> MOBILE NO :</b> {singleOrder?.distributor?.mobileNumber}
                </div>
                <Divider dashed />
                <div className="billing-details">
                  <b> BILLING ADDRESS:</b>
                  <span>
                    {singleOrder?.billingAddress?.addressLine1}&nbsp;
                    {singleOrder?.billingAddress?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.billingAddress?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.billingAddress?.zipCode}
                  </span>
                </div>
                <div className="billing-details">
                  <b> SHIPPING ADDRESS:</b>
                  <span>
                    {singleOrder?.address?.addressLine1}&nbsp;
                    {singleOrder?.address?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.address?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.address?.zipCode}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="table-section feature-prod-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowClassName={(record) =>
              record.isDisabled ? 'disabled-row' : null
            }
            columns={columns}
            data={activeRequests}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchActiveOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
