/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import './single-request.less';

// ICONS
import TextArea from 'antd/es/input/TextArea';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/other-icons/viewImg.svg';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import {
  approveDistributorRequest,
  getDistributorById,
  rejectDistributorRequest
} from '../../distributors-repository';

export function SingleRequest() {
  const [rejectForm] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // MODAL
  const [singleDistributor, setSingleDistributor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [value, setValue] = useState('DISTRIBUTOR');
  const [selectedId, setSelectedId] = useState('');

  const onLoad = async () => {
    const distributorId = searchParams.get('distributorId');
    setSelectedId(distributorId);
    await getDistributorById(distributorId).then((fetchedDistributor) => {
      setSingleDistributor(fetchedDistributor);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCancelApprove = () => {
    setValue('DISTRIBUTOR');
    setIsApproveModal(false);
  };
  const handleCancelReject = () => {
    setIsRejectModal(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const submitRejectForm = async (formValue) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Request processing'
    });
    const { comment } = formValue;

    await rejectDistributorRequest(selectedId, comment);
    message.open({
      key,
      type: 'success',
      content: 'Request completed'
    });
    rejectForm.resetFields();
    setIsRejectModal(false);
    setTimeout(() => {
      navigate('/requests');
    }, 2000);
  };

  const handleApproveRequest = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Request processing'
    });
    await approveDistributorRequest(selectedId, value);
    message.open({
      key,
      type: 'success',
      content: 'Request completed'
    });
    handleCancelApprove();
    setTimeout(() => {
      navigate('/requests');
    }, 2000);
  };

  return (
    <div className="distribution-content">
      {/* APPROVE MODAL */}
      <Modal
        title="ASSIGN TYPE"
        open={isApproveModal}
        onCancel={handleCancelApprove}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical" size={20}>
              <Radio value="DISTRIBUTOR">DISTRBUTOR / WHOLESALERS</Radio>
              <Radio value="RETAILER">RETAILERS / STORE</Radio>
              <Radio value="JOBBER">JOBBERS</Radio>
            </Space>
          </Radio.Group>
          <Button id="approve-btn" onClick={() => handleApproveRequest()}>
            APPROVE
          </Button>
        </div>
      </Modal>
      {/* REJECT MODAL */}
      <Modal
        title="ADD COMMENT"
        open={isRejectModal}
        onCancel={handleCancelReject}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form layout="vertical" form={rejectForm} onFinish={submitRejectForm}>
            <Form.Item label="Comment" name="comment">
              <TextArea
                autoSize={{
                  minRows: 5,
                  maxRows: 5
                }}
                type="text"
                placeholder="Enter comment for rejection"
              />
            </Form.Item>
            <Button id="reject-btn" htmlType="submit">
              REJECT
            </Button>
          </Form>
        </div>
      </Modal>
      <Header className="main-header req-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate('/requests')} />
          REQUEST
        </Typography.Title>
        {singleDistributor?.distributor?.request !== 'REJECTED' ? (
          <div className="btns-reqs">
            <Button id="reject-btn" onClick={() => setIsRejectModal(true)}>
              REJECT
            </Button>
            <Button id="approve-btn" onClick={() => setIsApproveModal(true)}>
              APPROVE
            </Button>
          </div>
        ) : (
          <div className="btns-reqs">
            {/* <Button id="reject-btn" onClick={() => setIsRejectModal(true)}>
              REJECT
            </Button> */}
            <Button id="approve-btn" onClick={() => setIsApproveModal(true)}>
              APPROVE
            </Button>
          </div>
        )}
      </Header>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className="single-request-content">
          <div className="request-information">
            <Row>
              <Col span={6}>
                <div className="req-info">
                  Name
                  <span className="span-info">
                    {singleDistributor?.distributor?.name}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Company Name
                  <span className="span-info">
                    {singleDistributor?.distributor?.companyName}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Business telephone number
                  <span className="span-info">
                    {singleDistributor?.distributor?.businessTelephoneNumber}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Business Point of Contact
                  <span className="span-info">
                    {singleDistributor?.distributor?.businessPointOfContact}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div className="req-info">
                  Mobile number (POC)
                  <span className="span-info">
                    {singleDistributor?.distributor?.mobileNumber}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Email Address
                  <span className="span-info">
                    {singleDistributor?.distributor?.email}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Address line 1
                  <span className="span-info">
                    {singleDistributor?.distributor?.addressLine1}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Address line 2
                  <span className="span-info">
                    {singleDistributor?.distributor?.addressLine2}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div className="req-info">
                  City
                  <span className="span-info">
                    {singleDistributor?.distributor?.city}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  State
                  <span className="span-info">
                    {singleDistributor?.distributor?.state}
                  </span>
                </div>
              </Col>
              <Col span={6}>
                <div className="req-info">
                  Zip-code
                  <span className="span-info">
                    {singleDistributor?.distributor?.zipCode}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="docs-container">
            <span className="doc-title">DOCUMENTS</span>
            <div className="request-information info-doc">
              <Row>
                <Col span={6}>
                  <div className="req-info">
                    Customer Driver License
                    <span className="span-info">
                      <span className="image-container">
                        <Avatar
                          src={
                            singleDistributor?.distributor?.documents[0]?.url
                          }
                          shape="square"
                        />
                        {!singleDistributor?.distributor?.documents[0]?.url
                          ? 'Not uploaded'
                          : ' CustomerDrivingLicense'}
                      </span>
                      <ViewIcon
                        onClick={() => {
                          const url = `${singleDistributor?.distributor?.documents[0]?.url}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Business License
                    <span className="span-info">
                      <span className="image-container">
                        <Avatar
                          src={
                            singleDistributor?.distributor?.documents[1]?.url
                          }
                          shape="square"
                        />
                        {!singleDistributor?.distributor?.documents[1]?.url
                          ? 'Not uploaded'
                          : 'BusinessLicense'}
                      </span>
                      <ViewIcon
                        onClick={() => {
                          const url = `${singleDistributor?.distributor?.documents[1]?.url}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Tobacco License
                    <span className="span-info">
                      <span className="image-container">
                        <Avatar
                          src={
                            singleDistributor?.distributor?.documents[2]?.url
                          }
                          shape="square"
                        />
                        {!singleDistributor?.distributor?.documents[2]?.url
                          ? 'Not uploaded'
                          : 'TobaccoLicense'}
                      </span>
                      <ViewIcon
                        onClick={() => {
                          const url = `${singleDistributor?.distributor?.documents[2]?.url}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Federal Document
                    <span className="span-info">
                      <span className="image-container">
                        <Avatar
                          src={
                            singleDistributor?.distributor?.documents[3]?.url
                          }
                          shape="square"
                        />
                        {!singleDistributor?.distributor?.documents[3]?.url
                          ? 'Not uploaded'
                          : 'FederalDocument'}
                      </span>
                      <ViewIcon
                        onClick={() => {
                          const url = `${singleDistributor?.distributor?.documents[3]?.url}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="user-comment">
            <span className="doc-title">COMMENT</span>
            <div className="comment-line">
              {singleDistributor?.distributor?.comment === null
                ? 'No Comment'
                : singleDistributor?.distributor?.comment}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
