import client from '../../apollo';
import { CREATE_ATTRIBUTE, UPDATE_ATTRIBUTE } from './graphql/mutation';
import { GET_ATTRIBUTES } from './graphql/query';

export const getAllAttributes = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ATTRIBUTES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getAllAttributes;
  } catch (error) {
    return [];
  }
};

export const createAttribute = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_ATTRIBUTE,
      fetchPolicy: 'network-only',
      variables: { data }
    });

    return response?.data?.createAttribute?.attribute;
  } catch (error) {
    return false;
  }
};

export const updateAttribute = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ATTRIBUTE,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateAttribute?.attribute;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
