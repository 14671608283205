import client from '../apollo';
import { GET_TOTAL_PENDING_ORDERS_DISTRIBUTOR } from './graphql/query';

export const getActiveData = async () => {
  try {
    const response = await client.query({
      query: GET_TOTAL_PENDING_ORDERS_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: {}
    });
    return response?.data?.getTotalPendingOrdersDistributor;
  } catch (error) {
    return [];
  }
};
