/* eslint-disable no-undef */
/* eslint-disable no-console */
import { RetweetOutlined } from '@ant-design/icons/lib/icons';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './edit-info.less';

// ICONS
import axios from 'axios';
import { ReactComponent as BackBtn } from '../../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as ViewIcon } from '../../../../../assets/other-icons/viewImg.svg';
import {
  getFileSignedUrl,
  uploadDistributorDocumentAsAdmin
} from '../../../../../common/common-repository';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import { US_STATES_CODES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import {
  getDistributorById,
  updateDistributor
} from '../../../distributors-repository';

export function EditInfo() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { required } = formValidatorRules;
  const { Option } = Select;

  const startingStateForDoc = {
    isUploading: false,
    url: '',
    type: 'UNKNOWN',
    title: ''
  };

  const [isLoading, setIsLoading] = useState(true);
  // const [isUpdating, setIsUpdating] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [cID, setCID] = useState(startingStateForDoc);
  const [bLicense, setBLicense] = useState(startingStateForDoc);
  const [tLicense, setTLicense] = useState(startingStateForDoc);
  const [fDoc, setFDoc] = useState(startingStateForDoc);
  const [singleDistributor, setSingleDistributor] = useState([]);

  const onLoad = async (distributorId) => {
    getDistributorById(distributorId).then((fetchedDistributor) => {
      setSingleDistributor(fetchedDistributor);

      form?.setFieldsValue({
        name: fetchedDistributor.distributor.name,
        cname: fetchedDistributor.distributor.companyName,
        bnum: fetchedDistributor.distributor.businessTelephoneNumber,
        bcontact: fetchedDistributor.distributor.businessPointOfContact,
        mnumber: fetchedDistributor.distributor.mobileNumber,
        email: fetchedDistributor.distributor.email,
        aline1: fetchedDistributor.distributor.addressLine1,
        aline2: fetchedDistributor.distributor.addressLine2,
        city: fetchedDistributor.distributor.city,
        state: fetchedDistributor.distributor.state,
        zip: fetchedDistributor.distributor.zipCode
      });

      const { documents = [] } = fetchedDistributor.distributor;
      setCID(startingStateForDoc);
      setBLicense(startingStateForDoc);
      setTLicense(startingStateForDoc);
      setFDoc(startingStateForDoc);
      for (let i = 0; i < documents.length; i += 1) {
        const document = documents[i];
        if (document.title === 'Customer Driving License') {
          setCID({
            isUploading: false,
            url: document.url,
            type: document.type,
            title: document.title
          });
        }
        if (document.title === 'Business License') {
          setBLicense({
            isUploading: false,
            url: document.url,
            type: document.type,
            title: document.title
          });
        }
        if (document.title === 'Tobacco License') {
          setTLicense({
            isUploading: false,
            url: document.url,
            type: document.type,
            title: document.title
          });
        }
        if (document.title === 'Federal Document') {
          setFDoc({
            isUploading: false,
            url: document.url,
            type: document.type,
            title: document.title
          });
        }
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    const distributorId = searchParams.get('distributorId');
    setSelectedId(distributorId);
    onLoad(distributorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beforeUpload = (file) => {
    console.log(file);
    const isLT1MB = file.size / 1024 / 1024 <= 1;
    if (!isLT1MB) {
      message.error(`File must smaller than 1MB!`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }

    return false;
  };

  // DOC-IMG UPLOAD
  const uploadDocument = async (selectedFile, docType) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be pdf/png/jpg/jpeg`);
      return;
    }
    if (docType === 0) {
      setCID({
        url: cID.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'Customer Driving License'
      });
    } else if (docType === 1) {
      setBLicense({
        url: bLicense.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'Business License'
      });
    } else if (docType === 2) {
      setTLicense({
        url: tLicense.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'Tobacco License'
      });
    } else {
      setFDoc({
        url: fDoc.url,
        type: 'UNKNOWN',
        isUploading: true,
        title: 'Federal Document'
      });
    }
    const response = await uploadDistributorDocumentAsAdmin(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    if (docType === 0) {
      setCID({
        url: url,
        type: fileType,
        isUploading: false,
        title: 'Customer Driving License'
      });
    } else if (docType === 1) {
      setBLicense({
        url: url,
        type: fileType,
        isUploading: false,
        title: 'Business License'
      });
    } else if (docType === 2) {
      setTLicense({
        url: url,
        type: fileType,
        isUploading: false,
        title: 'Tobacco License'
      });
    } else {
      setFDoc({
        url: url,
        type: fileType,
        isUploading: false,
        title: 'Federal Document'
      });
    }
  };

  const submitEditInfo = async (formValue) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Updating information'
    });
    const {
      name,
      cname,
      bnum,
      bcontact,
      mnumber,
      aline1,
      aline2,
      city,
      state,
      zip
    } = formValue;
    const docs1 = [
      {
        title: cID.title,
        url: cID.url,
        type: cID.type
      },
      {
        title: bLicense.title,
        url: bLicense.url,
        type: bLicense.type
      },
      {
        title: tLicense.title,
        url: tLicense.url,
        type: tLicense.type
      },
      {
        title: fDoc.title,
        url: fDoc.url,
        type: fDoc.type
      }
    ];
    await updateDistributor({
      distributorId: selectedId,
      name,
      companyName: cname,
      businessTelephoneNumber: bnum.toString(),
      businessPointOfContact: bcontact,
      mobileNumber: mnumber.toString(),
      addressLine1: aline1,
      addressLine2: aline2,
      city,
      state,
      zipCode: zip.toString(),
      documents: docs1
    });
    message.open({
      key,
      type: 'success',
      content: 'Information updated'
    });
  };

  return (
    <div className="distribution-content">
      <Header className="main-header req-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate('/')} />
          EDIT INFORMATION
        </Typography.Title>
      </Header>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div className="single-request-content edit-dist-content">
          <Form form={form} onFinish={submitEditInfo}>
            <div className="request-information">
              <Row>
                <Col span={6}>
                  <div className="req-info">
                    Name
                    <span className="span-info edit-info-input">
                      <Form.Item name="name" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Company Name
                    <span className="span-info edit-info-input">
                      <Form.Item name="cname" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Business telephone number
                    <span className="span-info edit-info-input">
                      <Form.Item name="bnum" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Business Point of Contact
                    <span className="span-info edit-info-input">
                      <Form.Item name="bcontact" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div className="req-info">
                    Mobile number (POC)
                    <span className="span-info edit-info-input">
                      <Form.Item name="mnumber" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Email Address
                    <span className="span-info edit-info-input">
                      <Form.Item name="email" rules={[required]}>
                        <Input disabled />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Address line 1
                    <span className="span-info edit-info-input">
                      <Form.Item name="aline1" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Address line 2
                    <span className="span-info edit-info-input">
                      <Form.Item name="aline2">
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div className="req-info">
                    City
                    <span className="span-info edit-info-input">
                      <Form.Item name="city" rules={[required]}>
                        <Input />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    State
                    <Form.Item
                      className="state-item"
                      name="state"
                      rules={[required]}
                    >
                      <Select className="state-selection">
                        {US_STATES_CODES.map((state) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <Option value={state.name} label={state.name}>
                              {state.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="req-info">
                    Zip-code
                    <span className="span-info edit-info-input">
                      <Form.Item name="zip" rules={[required]}>
                        <InputNumber controls={false} />
                      </Form.Item>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="docs-container">
              <span className="doc-title">DOCUMENTS</span>
              <div className="request-information info-doc">
                <Row>
                  <Col span={6}>
                    <div className="req-info">
                      Customer Driving License
                      <span className="span-info upload-image-edit">
                        <span className="image-container">
                          {cID.isUploading ? (
                            <Spin size="small" />
                          ) : (
                            <Avatar src={cID?.url} shape="square" />
                          )}
                          {!cID.url
                            ? 'Not uploaded'
                            : ' CustomerDrivingLicense'}
                        </span>
                        <span className="re-upload-icons">
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(doc) => {
                              uploadDocument(doc, 0);
                            }}
                          >
                            <RetweetOutlined />
                          </Upload>
                          <ViewIcon
                            onClick={() => {
                              window.open(cID?.url, '_blank');
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="req-info">
                      Business License
                      <span className="span-info upload-image-edit">
                        <span className="image-container">
                          {bLicense.isUploading ? (
                            <Spin size="small" />
                          ) : (
                            <Avatar src={bLicense?.url} shape="square" />
                          )}
                          {!bLicense.url ? 'Not uploaded' : 'BusinessLicense'}
                        </span>
                        <span className="re-upload-icons">
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(doc) => {
                              uploadDocument(doc, 1);
                            }}
                          >
                            <RetweetOutlined />
                          </Upload>
                          <ViewIcon
                            onClick={() => {
                              window.open(bLicense?.url, '_blank');
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="req-info">
                      Tobacco License
                      <span className="span-info upload-image-edit">
                        <span className="image-container">
                          {tLicense.isUploading ? (
                            <Spin size="small" />
                          ) : (
                            <Avatar src={tLicense?.url} shape="square" />
                          )}
                          {!tLicense.url ? 'Not uploaded' : 'TobaccoLicense'}
                        </span>
                        <span className="re-upload-icons">
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(doc) => {
                              uploadDocument(doc, 2);
                            }}
                          >
                            <RetweetOutlined />
                          </Upload>
                          <ViewIcon
                            onClick={() => {
                              window.open(tLicense?.url, '_blank');
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="req-info">
                      Federal Document
                      <span className="span-info upload-image-edit">
                        <span className="image-container">
                          {fDoc.isUploading ? (
                            <Spin size="small" />
                          ) : (
                            <Avatar src={fDoc?.url} shape="square" />
                          )}
                          {!fDoc.url ? 'Not uploaded' : 'FederalDocument'}
                        </span>
                        <span className="re-upload-icons">
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(doc) => {
                              uploadDocument(doc, 3);
                            }}
                          >
                            <RetweetOutlined />
                          </Upload>
                          <ViewIcon
                            onClick={() => {
                              window.open(fDoc?.url, '_blank');
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="user-comment">
                <span className="doc-title">COMMENT</span>
                <div className="comment-line edit-comment">
                  {singleDistributor?.distributor?.comment === null
                    ? 'No Comment'
                    : singleDistributor?.distributor?.comment}
                </div>
              </div>
            </div>
            <div className="submit-info-btn">
              <Button htmlType="submit">SAVE INFO</Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}
