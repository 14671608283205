/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Empty,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import './featured-products.less';

import { HolderOutlined } from '@ant-design/icons/lib/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DeleteBtn } from '../../assets/other-icons/deletebtn.svg';
import SearchComponent from '../../common/SearchDebounce';
import LoaderComponent from '../../common/components/LoaderComponent';
import {
  addFeatureProduct,
  getEnableProducts,
  getFeatureProducts,
  rearrangeFeatureProduct,
  removeFeatureProduct
} from './feature-products-repository';

import { ReactComponent as CheckBtn } from '../../assets/other-icons/addedBtn.svg';

const RowContext = createContext({});
function DragHandle() {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move'
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
}

function Row(props) {
  const { style: rowStyle, ...restProps } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key']
  });

  const style = {
    ...rowStyle,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999
        }
      : {})
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners
    }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...restProps} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
}

export function FeaturedProducts() {
  // STATES
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [enabledProducts, setEnabledProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [query, setQuery] = useState('');
  const [loadingData, setLoadingData] = useState(false);

  const fetchFeaturedProducts = async () => {
    try {
      const response = await getFeatureProducts();
      const products = response.products.map((product, index) => ({
        ...product,
        key: product.id || index // Ensure each category has a unique key
      }));
      setFeaturedProducts(products);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchEnabledProducts = async ({ currentQuery }) => {
    setLoadingData(true);
    try {
      const response = await getEnableProducts(currentQuery);
      setEnabledProducts(response.products.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchFeaturedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = ({ active, over }) => {
    if (!over) {
      return; // Do nothing if the item is dropped outside of valid drop targets
    }
    if (active.id !== over?.id) {
      setFeaturedProducts((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const deleteSelectedCategory = async (index, ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting featured product'
    });
    await removeFeatureProduct(ID);
    const delFeatureProd = featuredProducts.slice();
    delFeatureProd.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    setFeaturedProducts(delFeatureProd);
  };

  const handleCancelCreate = () => {
    setIsCreateModal(false);
  };

  const handleSubmit = async () => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Updating'
    });
    const orderedIdsArray = featuredProducts.map((product) => product.id);
    await rearrangeFeatureProduct(orderedIdsArray);
    message.open({
      key,
      type: 'success',
      content: 'Updated'
    });
    message.destroy(key);
  };

  const columns = [
    {
      key: 'sort',
      align: 'center',
      width: 80,
      render: () => <DragHandle />
    },
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'ATTRIBUTE',
      dataIndex: 'attr',
      key: '3',
      render: (value, record) => {
        return record?.attribute?.name;
      }
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: '4'
    },

    {
      title: '',
      dataIndex: 'btns',
      key: '8',
      render: (value, record, index) => {
        return (
          <div className="btns-section">
            <Popconfirm
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                    width: '10px'
                  }}
                />
              }
              title="DELETE FEATURED PRODUCT"
              description="ARE YOU SURE YOU WANT TO DELETE THIS FEATURED PRODUCT"
              onConfirm={() => deleteSelectedCategory(index, record.id)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
          </div>
        );
      }
    }
    // {
    //   title: '',
    //   dataIndex: 'moveButtons',
    //   key: '9',
    //   render: (value, record, index) => {
    //     return (
    //       <div className="btns-section">
    //         <Button
    //           style={{
    //             border: 'none'
    //           }}
    //           onClick={() => moveItem(index, index - 1)}
    //           disabled={index === 0}
    //         >
    //           <CaretUpOutlined />
    //         </Button>
    //         <Button
    //           style={{
    //             border: 'none'
    //           }}
    //           onClick={() => moveItem(index, index + 1)}
    //           disabled={index === featuredProducts.length - 1}
    //         >
    //           <CaretDownOutlined />
    //         </Button>
    //       </div>
    //     );
    //   }
    // }
  ];

  const addFeatureProd = async (ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Adding product to featured list'
    });
    await addFeatureProduct(ID).then((res) => {
      if (res) {
        fetchFeaturedProducts();
        fetchEnabledProducts({
          currentQuery: query
        });
        message.open({
          key,
          type: 'success',
          content: 'Product added to featured list'
        });
      }
    });
  };

  return (
    <div className="distribution-content ">
      {/* CREATE MODAL */}
      <Modal
        title="ADD FEATURED PRODUCTS"
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content featured-modal">
          <SearchComponent
            debounceTime={1}
            getData={(value) => {
              setQuery(value);
              setLoadingData(true);
              fetchEnabledProducts({
                currentQuery: value
              });
            }}
          />
          {loadingData ? (
            <Spin className="feature-loader" />
          ) : (
            <div className="search-results">
              {enabledProducts ? (
                enabledProducts.map((product) => {
                  return (
                    <div className="product-card" key={product.id}>
                      <div className="image-container">
                        <Avatar
                          shape="square"
                          src={product?.defaultVariation?.images[0]}
                          size={60}
                        />
                      </div>
                      <div className="other-content">
                        <span className="product-info">{product?.name}</span>
                        {product?.isFeature ? (
                          <CheckBtn />
                        ) : (
                          <Button onClick={() => addFeatureProd(product.id)}>
                            ADD
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty />
              )}
            </div>
          )}
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title">
          FEATURED PRODUCTS
        </Typography.Title>
        <div className="btn-header-section">
          <Button
            onClick={() => {
              setIsCreateModal(true);
              fetchEnabledProducts({
                currentQuery: query
              });
            }}
          >
            ADD NEW
          </Button>
          <Button onClick={handleSubmit}>SAVE</Button>
        </div>
      </Header>
      <div className="feature-products-section">
        <div className="products-note">
          <span className="note-design" />
          <span className="note-content">
            There are the product currently being featured on the website. You
            can add upto 8 prodcts to be featured by clicking on Add New.
          </span>
        </div>
        <div className="table-section feature-prod-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                items={featuredProducts.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  pagination={false}
                  rowKey="key"
                  components={{
                    body: {
                      row: Row
                    }
                  }}
                  columns={columns}
                  dataSource={featuredProducts}
                />
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </div>
  );
}
