/* eslint-disable no-console */
import {
  Avatar,
  Col,
  Divider,
  Empty,
  Form,
  InputNumber,
  Modal,
  Row,
  Tag
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../../../../common/components/CommonTable';
// import './active-order.less';

import { ReactComponent as OrderEye } from '../../../../../assets/other-icons/orderEye.svg';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { getOrderById, getOrders } from '../../../orders-repository';

export function RejectedOrders({ query }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [requestOrderForm] = Form.useForm();
  const { required } = formValidatorRules;
  // STATES
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [orderID, setOrderID] = useState('');
  const [, setCurrentGlobalSkip] = useState(0);
  const [activeRequests, setActiveRequests] = useState([]);
  const [singleOrder, setSingleOrder] = useState([]);
  const [reqProducts, setReqProducts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState();

  const fetchActiveOrders = async ({ currentSkip }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOrders(
        {
          skip: currentSkip,
          limit: limit,
          query: query
        },
        'REJECTED'
      );

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveRequests(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchActiveOrders({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleCancelCreate = () => {
    setIsCreateModal(false);
  };

  const openOrderModalData = async (ID) => {
    setLoadingData(true);
    const response = await getOrderById(ID);
    setSingleOrder(response.order);
    setReqProducts(response?.order?.orderSummary?.products.slice());
    requestOrderForm.setFieldsValue({
      note: response?.order?.note,
      scharge: response?.order?.shippingCharges
    });
    setIsCreateModal(true);
    setLoadingData(false);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1'
    },
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: '2'
    },
    {
      title: 'RECIPIENT NAME',
      dataIndex: 'serialNumber',
      key: '3',
      render: (value, record) => {
        return record?.distributor?.name;
      }
    },
    {
      title: 'NO. OF ITEMS',
      dataIndex: 'serialNumber',
      key: '4',
      render: (value, record) => {
        return record.items.length;
      }
    },
    {
      title: 'PLACED ON',
      dataIndex: 'createdAt',
      key: '5',
      render: (value) => {
        return (
          <div>
            {moment(value).format('MM/DD/YYYY')} | {moment(value).format('LT')}
          </div>
        );
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'total',
      key: '6',
      render: (value) => {
        return `$ ${value}`;
      }
    },
    {
      title: 'IS PAID',
      dataIndex: 'isPaid',
      key: '7',
      render: (value) => {
        return value ? (
          <Tag className="paid-tag">YES</Tag>
        ) : (
          <Tag className="pending-tag">PENDING</Tag>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'orderStatus',
      key: '8',
      render: () => {
        return <Tag className="pending-tag rejected-tag">REJECTED</Tag>;
      }
    },
    {
      title: '',
      dataIndex: 'orderId',
      key: '9',
      render: (value, record) => {
        return (
          <OrderEye
            onClick={() => {
              setOrderID(value);
              openOrderModalData(record.id);
            }}
            className="edit-icon "
          />
        );
      }
    }
  ];

  const rejectOrderForm = (formValue) => {
    console.log({
      NOTE: formValue.note,
      SHIPPING_CHARGE: formValue.scharge
    });
    requestOrderForm.resetFields();
    setIsCreateModal(false);
  };

  const approveOrderForm = (formValue) => {
    console.log({
      NOTE: formValue.note,
      SHIPPING_CHARGE: formValue.scharge
    });
    requestOrderForm.resetFields();
    setIsCreateModal(false);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={orderID}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal active-modal pending-modal"
        centered
      >
        <Divider />
        <div className="modal-content active-content">
          <Row>
            <Col>
              {' '}
              <div className="left">
                <div className="prod-list">
                  {reqProducts.length < 1 ? (
                    <div className="no-data">
                      <Empty />
                    </div>
                  ) : (
                    reqProducts.map((prod) => {
                      return (
                        <div key={prod.purchasedVariant.variantId}>
                          <div className="products-section">
                            <div className="product-card">
                              <div className="left-sect">
                                <Avatar
                                  src={prod.purchasedVariant.image}
                                  size={100}
                                  shape="square"
                                />
                              </div>
                              <div className="right-sect">
                                <div className="upper-sect">
                                  <section className="info-sect">
                                    <section className="prod-title">
                                      {prod.title}
                                    </section>
                                    <section className="prod-flavour">
                                      {prod.purchasedVariant.title}
                                    </section>
                                  </section>
                                </div>
                                <div className="lower-sect">
                                  <section className="product-price">
                                    $ {prod.purchasedVariant.price}
                                  </section>
                                  <section>
                                    <div className="counter">
                                      <section className="minus-part">
                                        -
                                      </section>
                                      <section className="num-part">
                                        {prod.purchasedVariant.qty}
                                      </section>
                                      <section className="plus-part">+</section>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      );
                    })
                  )}
                </div>
                <Form
                  layout="vertical"
                  form={requestOrderForm}
                  onFinish={approveOrderForm || rejectOrderForm}
                >
                  <Form.Item label="Add Note" name="note" rules={[required]}>
                    <TextArea
                      autoSize={{
                        minRows: 4,
                        maxRows: 4
                      }}
                      type="text"
                      disabled
                      placeholder="Enter note"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Shipping Charges"
                    name="scharge"
                    rules={[required]}
                  >
                    <InputNumber
                      className="shipping-charges"
                      placeholder="Enter shipping charges"
                      controls={false}
                      disabled
                    />
                  </Form.Item>
                  <Divider />
                  <div className="total-price-sect">
                    <section>TOTAL</section>
                    <section>${singleOrder?.orderSummary?.total}</section>
                  </div>
                  <Divider />
                </Form>
              </div>
            </Col>
            <Col>
              <div className="right ">
                <div className="customer-details">CUSTOMER DETAILS</div>
                <Divider dashed />
                <div className="customer-name">
                  <b> NAME:</b> {singleOrder?.distributor?.name}
                </div>
                <div className="customer-name">
                  <b> MOBILE NO :</b> {singleOrder?.distributor?.mobileNumber}
                </div>
                <Divider dashed />
                <div className="billing-details">
                  <b> BILLING ADDRESS:</b>
                  <span>
                    {singleOrder?.billingAddress?.addressLine1}&nbsp;
                    {singleOrder?.billingAddress?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.billingAddress?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.billingAddress?.zipCode}
                  </span>
                </div>
                <div className="billing-details">
                  <b> SHIPPING ADDRESS:</b>
                  <span>
                    {singleOrder?.address?.addressLine1}&nbsp;
                    {singleOrder?.address?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.address?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.address?.zipCode}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="table-section feature-prod-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowClassName={(record) =>
              record.isDisabled ? 'disabled-row' : null
            }
            columns={columns}
            data={activeRequests}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchActiveOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
