import { EditFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { formValidatorRules } from '../../common/utils';
import './config.less';
import {
  getRemoteConfigValue,
  updateRemoteConfigValue
} from './configurations-repository';

export function Configurations() {
  const { required } = formValidatorRules;
  const [configForm] = Form.useForm();
  // STATES
  const [configValues, setConfigValues] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [remoteName, setRemoteName] = useState('');

  const fetchConfigValues = async () => {
    try {
      const response = await getRemoteConfigValue();
      setConfigValues(response.data.slice());
    } catch (error) {
      //
    }
    setIsLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchConfigValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    configForm.resetFields();
    setOpenConfigModal(false);
  };

  const submitConfigForm = async (formValue) => {
    const data = {
      template: remoteName,
      value: formValue.value
    };
    setIsSavingData(true);
    await updateRemoteConfigValue(data);
    configForm.resetFields();
    setIsSavingData(false);
    setOpenConfigModal(false);
    fetchConfigValues();
  };

  const columns = [
    {
      title: 'Tempelate',
      dataIndex: 'template',
      key: '1'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: '2'
    },
    {
      title: 'EDIT',
      dataIndex: 'template',
      key: '3',
      render: (value, record) => {
        setRemoteName(value);
        return (
          <EditFilled
            className="edit-btn-config"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log(record);
              setOpenConfigModal(true);
              configForm.setFieldsValue({
                template: record.template,
                value: record.value
              });
            }}
          />
        );
      }
    }
  ];

  return (
    <div className="distribution-content">
      {/* DISABLE DISTRIBUTOR */}
      <Modal
        title="CONFIGURATIONS"
        className="disable-modal"
        centered
        footer={null}
        open={openConfigModal}
        onCancel={handleCancel}
      >
        <div className="modal-content config-modal">
          <Form
            layout="vertical"
            form={configForm}
            className="config-form"
            onFinish={submitConfigForm}
          >
            <Form.Item name="value" label="Value" rules={[required]}>
              <Input />
            </Form.Item>
            <Button
              loading={isSavingData}
              className="configuration-btn"
              htmlType="submit"
            >
              UPDATE INFO
            </Button>
          </Form>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title">
          CONFIGURATIONS
        </Typography.Title>
      </Header>
      <div className="distributors-section">
        <div className="table-section">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              rowClassName={(record) =>
                record.isDisabled ? 'disabled-row' : null
              }
              columns={columns}
              data={configValues}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
