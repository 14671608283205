import { gql } from '@apollo/client';

export const GET_DISTRIBUTORS = gql`
  query getDistributors($filters: DistributorsInput) {
    getDistributors(filters: $filters) {
      distributors {
        id
        name
        companyName
        businessTelephoneNumber
        # businessAddress
        addressLine1
        addressLine2
        businessPointOfContact
        mobileNumber
        email
        documents {
          title
          url
          type
        }
        type
        request
        comment
        isDisabled
      }
      total
      totalRequest
    }
  }
`;

export const GET_PENDING_DISTRIBUTORS = gql`
  query getPendingDistributorRequests($filters: FilterDataInput) {
    getPendingDistributorRequests(filters: $filters) {
      distributors {
        id
        name
        companyName
        businessTelephoneNumber
        # businessAddress
        addressLine1
        addressLine2
        businessPointOfContact
        mobileNumber
        email
        documents {
          title
          url
          type
        }
        request
        comment
        type
        isDisabled
      }
      total
    }
  }
`;

export const GET_REJECTED_DISTRIBUTORS = gql`
  query getRejectedDistributorRequests($filters: FilterDataInput) {
    getRejectedDistributorRequests(filters: $filters) {
      distributors {
        id
        name
        companyName
        businessTelephoneNumber
        # businessAddress
        addressLine1
        addressLine2
        businessPointOfContact
        mobileNumber
        email
        documents {
          title
          url
          type
        }
        request
        comment
        isDisabled
        type
      }
      total
    }
  }
`;

export const GET_DISTRIBUTOR_BY_ID = gql`
  query getDistributorById($distributorId: String!) {
    getDistributorById(distributorId: $distributorId) {
      distributor {
        id
        name
        companyName
        businessTelephoneNumber
        # businessAddress
        addressLine1
        addressLine2
        city
        state
        zipCode
        businessPointOfContact
        mobileNumber
        email
        type
        request
        comment
        isDisabled
        documents {
          title
          url
          type
        }
      }
    }
  }
`;

export const GET_ORDERS_AS_PER_DISTRIBUTOR = gql`
  query getOrdersBelongToDistributor(
    $filters: FilterDataInput
    $distributorId: String!
  ) {
    getOrdersBelongToDistributor(
      filters: $filters
      distributorId: $distributorId
    ) {
      orders {
        id
        orderId
        serialNumber
        isPlaced
        orderStatus
        isPaid
        total
        subTotal
        items {
          qty
        }
      }
      total
    }
  }
`;
export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
