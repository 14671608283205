import { gql } from '@apollo/client';

export const GET_CONFIGURATIONS = gql`
  query getRemoteConfigValue {
    getRemoteConfigValue {
      data {
        template
        value
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
