import { REGEX } from './constants';

export const formValidatorRules = {
  required: {
    validator(rule, value) {
      const currentValue = value || '';
      if (currentValue.toString().trim() === '') {
        return Promise.reject(new Error('Required'));
      }
      return Promise.resolve();
    }
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  })
};

export const getImageData = (data) => {
  if (!data) {
    return [];
  }
  const separatedValue = data.split(',');
  return separatedValue[0];
};
