/* eslint-disable no-console */
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Select, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GLOBAL_PAGINATION_LIMIT, ROUTES } from '../../common/constants';
import './distribution.less';

// ICONS
import { ReactComponent as ButtonLogo } from '../../assets/other-icons/button.svg';
import { ReactComponent as DisableIcon } from '../../assets/other-icons/disable.svg';
import { ReactComponent as EditIcon } from '../../assets/other-icons/edit.svg';
import { ReactComponent as EnableIcon } from '../../assets/other-icons/enable.svg';

import SearchComponent from '../../common/SearchDebounce';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import {
  getDistributors,
  toggleEnableDistributor
} from './distributors-repository';

export function Distributors() {
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [distributors, setDistributors] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [, setCurrentGlobalSkip] = useState(0);
  const [reqNo, setReqNo] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState('');
  const [openDisableUser, setOpenDisableUser] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  const fetchDistributors = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getDistributors({
        skip: currentSkip,
        limit: limit,
        query: currentQuery,
        type: filterValue
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setDistributors(response.distributors.slice());
      setReqNo(response);
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchDistributors({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, setFilterValue]);

  // ENABLE/DISABLE
  const toggleEnabled = async (index) => {
    distributors[index] = {
      ...distributors[index],
      isDisabled: !(distributors[index].isDisabled || false)
    };
    toggleEnableDistributor(distributors[index].id);
    setDistributors(distributors.slice());
  };

  const handleChange = (value) => {
    setFilterValue(value);
  };

  const selectRow = (ID, IS_DISABLE) => {
    if (IS_DISABLE) {
      message.error("CAN'T EDIT DISABLED DISTRIBUTOR");
      return;
    }
    {
      const url = `${ROUTES.EDIT_INFO}?distributorId=${ID}`;
      // eslint-disable-next-line no-undef
      navigate(url);
    }
  };

  const selectRowSingle = (ID) => {
    const url = `${ROUTES.SINGLE_CUSTOMER}?customerId=${ID}`;
    // eslint-disable-next-line no-undef
    navigate(url);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'sno',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'COMPANY NAME',
      dataIndex: 'companyName',
      key: '3'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email',
      key: '4'
    },
    {
      title: 'BUSINESS POC',
      dataIndex: 'businessTelephoneNumber',
      key: '5'
    },
    {
      title: 'MOBILE NUMBER (POC)',
      dataIndex: 'mobileNumber',
      key: '6'
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: '7'
    },
    {
      title: '',
      dataIndex: 'isDisabled',
      key: '8',
      render: (value, record, index) => {
        return value ? (
          <div className="btns-section">
            <ShoppingCartOutlined />
            <EditIcon
              className="edit-icon"
              onClick={() => selectRow(record.id, record.isDisabled)}
            />
            <EnableIcon
              className="disable-icon"
              onClick={() => {
                toggleEnabled(index);
              }}
            />
          </div>
        ) : (
          <div className="btns-section">
            <ShoppingCartOutlined
              size={20}
              onClick={() => selectRowSingle(record.id, record.isDisabled)}
              className="customer-orders"
            />
            <EditIcon
              className="edit-icon"
              onClick={() => selectRow(record.id, record.isDisabled)}
            />
            <DisableIcon
              className="disable-icon"
              onClick={() => {
                setCurrentSelectedIndex(index);
                setTimeout(() => {
                  setOpenDisableUser(true);
                }, 300);
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="distribution-content">
      {/* DISABLE DISTRIBUTOR */}
      <Modal
        title="ASSIGN TYPE"
        className="disable-modal"
        centered
        footer={null}
        open={openDisableUser}
        onCancel={() => setOpenDisableUser(false)}
      >
        <div className="modal-content">
          <Divider />
          <span>ARE YOU SURE YOU WANT TO DISABLE THIS DISTRIBUTOR?</span>
          <Button
            id="disable-btn"
            onClick={() => {
              toggleEnabled(currentSelectedIndex);
              setOpenDisableUser(false);
            }}
          >
            DISABLE
          </Button>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title">
          DISTRIBUTORS
        </Typography.Title>
        <Button
          onClick={() => {
            const url = ROUTES.REQUESTS;
            navigate(url);
          }}
        >
          <ButtonLogo /> REQUESTS: {reqNo?.totalRequest}
        </Button>
      </Header>
      <div className="distributors-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Distributors"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchDistributors({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>

          <Select
            placeholder="FILTER"
            defaultValue="ALL"
            style={{
              width: 120
            }}
            onChange={handleChange}
            options={[
              {
                value: null,
                label: 'ALL'
              },
              {
                value: 'DISTRIBUTOR',
                label: 'DISTRIBUTOR'
              },
              {
                value: 'RETAILER',
                label: 'RETAILER'
              },
              {
                value: 'JOBBER',
                label: 'JOBBER'
              }
            ]}
          />
        </div>
        <div className="table-section">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              rowClassName={(record) =>
                record.isDisabled ? 'disabled-row' : null
              }
              columns={columns}
              data={distributors}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchDistributors({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
