/* eslint-disable no-console */
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import './categories.less';

import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../common/SearchDebounce';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';

import { ReactComponent as DeleteBtn } from '../../assets/other-icons/deletebtn.svg';
import { ReactComponent as EditIcon } from '../../assets/other-icons/edit.svg';
import { ReactComponent as FilledStar } from '../../assets/other-icons/filledstar.svg';
import { ReactComponent as UnStar } from '../../assets/other-icons/unstar.svg';
import { ReactComponent as ViewIcon } from '../../assets/other-icons/viewPageEye.svg';
import { GLOBAL_PAGINATION_LIMIT, ROUTES_HELPER } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  toggleFeatureCategory,
  toggleVisibilityCategory,
  updateCategory
} from './categories-repository';

export function Categories({ user }) {
  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  const [createForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const navigate = useNavigate();
  const { required } = formValidatorRules;
  // STATES
  const [categories, setCategories] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();

  const fetchAllCategories = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllCategories({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCategories(response.categories.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchAllCategories({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRow = (ID) => {
    // const url = `/${ROUTES_HELPER.CATEGORIES}/${ID}`;
    // eslint-disable-next-line no-undef, no-restricted-globals
    const url = `${location.pathname}/${ID}/${ROUTES_HELPER.SUB_CATEGORIES}`;
    navigate(url);
  };

  const handleCancelCreate = () => {
    createForm.resetFields();
    setIsUpdateModal(false);
    setIsCreateModal(false);
  };

  const categoryUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateModal(true);
    setTimeout(() => {
      setIsCreateModal(true);
      setLoadingData(false);
      createForm.setFieldsValue({
        name: record.name,
        description: record.description
      });
    }, 500);
  };

  const deleteSelectedCategory = async (index, ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting category'
    });
    await deleteCategory(ID);
    const delCategory = categories.slice();
    delCategory.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    setCategories(delCategory);
  };

  // TOGGLE FEATURE
  const toggleFeatured = async (record, index) => {
    if (record.isDisable) {
      return message.error("Can't feature disabled category");
    }
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });
    categories[index] = {
      ...categories[index],
      isFeature: !categories[index].isFeature
    };
    await toggleFeatureCategory(categories[index].id);
    setCategories(categories.slice());
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
  };

  // TOGGLE VISIBILITY
  const toggleVisibility = async (index) => {
    categories[index] = {
      ...categories[index],
      isVisible: !categories[index].isVisible
    };
    toggleVisibilityCategory(categories[index].id);
    setCategories(categories.slice());
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1'
      // render: (value, record, index) => {
      //   return 1 + index;
      // }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'SUB CATEGORIES',
      dataIndex: 'name',
      key: '3',
      render: (value, record) => {
        return record?.subCategories?.length;
      }
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: '4'
    },
    {
      title: 'PHONE VISIBILITY ',
      dataIndex: 'isVisible',
      key: '5',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => toggleVisibility(index)}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'isDisable',
      key: '6',
      render: (value, record, index) => {
        if (isManager) {
          return value ? (
            <div className="btns-section">
              <ViewIcon
                className="edit-icon"
                onClick={() => selectRow(record.id, record.isDisabled)}
              />
            </div>
          ) : (
            <div className="btns-section">
              <ViewIcon
                className="edit-icon"
                onClick={() => selectRow(record.id, record.isDisabled)}
              />
            </div>
          );
        }
        return value ? (
          <div className="btns-section">
            {record.isFeature ? (
              <FilledStar
                onClick={() => {
                  toggleFeatured(record, index);
                }}
                className="edit-icon"
              />
            ) : (
              <UnStar
                onClick={() => {
                  toggleFeatured(record, index);
                }}
                className="edit-icon"
              />
            )}
            <ViewIcon
              className="edit-icon"
              onClick={() => selectRow(record.id, record.isDisabled)}
            />
            <EditIcon
              className="edit-icon"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  categoryUpdate(index, value, record);
                }, 200);
              }}
            />
            <Popconfirm
              title="DELETE CATEGORY"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                    width: '10px'
                  }}
                />
              }
              description="ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY"
              onConfirm={() => deleteSelectedCategory(index, record.id)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
          </div>
        ) : (
          <div className="btns-section">
            {record.isFeature ? (
              <FilledStar
                onClick={() => {
                  toggleFeatured(record, index);
                }}
                className="edit-icon"
              />
            ) : (
              <UnStar
                onClick={() => {
                  toggleFeatured(record, index);
                }}
                className="edit-icon"
              />
            )}
            <ViewIcon
              className="edit-icon"
              onClick={() => selectRow(record.id, record.isDisabled)}
            />
            <EditIcon
              className="edit-icon"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  categoryUpdate(index, value, record);
                }, 200);
              }}
            />
            <Popconfirm
              title="DELETE CATEGORY"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                    width: '10px'
                  }}
                />
              }
              description="ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY"
              onConfirm={() => deleteSelectedCategory(index, record.id)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const submitCreateForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const createNewAttribute = await createCategory({
      name,
      description
    });
    if (
      categories.length === 0 ||
      categories.length < limit ||
      categories[categories.length - 1].serialNumber + 1 ===
        createNewAttribute.serialNumber
    ) {
      const updatedCategories = categories.slice();
      updatedCategories.push(createNewAttribute);
      setCategories(updatedCategories.slice());
    }
    createForm.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    fetchAllCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  const submitUpdatedForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const updateCreatedCategory = await updateCategory({
      name,
      description,
      categoryId: selectedId
    });
    const updatedCategories = categories.slice();
    updatedCategories[currentSelectedIndex] = updateCreatedCategory;
    setCategories(updatedCategories);
    createForm?.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    setIsUpdateModal(false);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={isUpdateModal ? 'UPDATE CATEGORY' : 'NEW CATEGORY'}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={isUpdateModal ? submitUpdatedForm : submitCreateForm}
          >
            <Form.Item label="Name" name="name" rules={[required]}>
              <Input id="attribute-inp" placeholder="Enter category name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[required]}
            >
              <TextArea
                autoSize={{
                  minRows: 5,
                  maxRows: 5
                }}
                type="text"
                placeholder="Enter description"
              />
            </Form.Item>
            <Button loading={isSavingData} id="create-btn" htmlType="submit">
              {isUpdateModal ? '   UPDATE CATEGORY' : '   ADD CATEGORY'}
            </Button>
          </Form>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title">CATEGORIES</Typography.Title>
        <Button onClick={() => setIsCreateModal(true)}>ADD CATEGORY</Button>
      </Header>
      <div className="categories-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Category"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchAllCategories({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>
        </div>

        <div className="table-section categories-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              rowClassName={(record) =>
                record.isDisable ? 'disabled-row' : null
              }
              columns={columns}
              data={categories}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchAllCategories({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
