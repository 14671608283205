import { gql } from '@apollo/client';

export const GET_ALL_CATEGORIES = gql`
  query getAllCategories($filters: FilterDataInput) {
    getAllCategories(filters: $filters) {
      categories {
        id
        name
        description
        isFeature
        isDisable
        isVisible
        serialNumber
        order
        subCategories {
          id
          name
          description
        }
      }
      total
    }
  }
`;

export const GET_SUB_CATEGORIES = gql`
  query getSubCategories($filters: FilterDataInput, $categoryId: String!) {
    getSubCategories(filters: $filters, categoryId: $categoryId) {
      subCategories {
        id
        name
        description
        isDisable
        products {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($filters: FilterDataInput, $subCategoryId: String) {
    getProducts(filters: $filters, subCategoryId: $subCategoryId) {
      total
      products {
        id
        name
        description
        isDisable
        attribute {
          id
          name
          description
        }
        categoryData {
          id
        }
        subCategoryData {
          id
        }
        defaultVariation {
          id
          name
          images
          searchableName
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query getProductById($productId: String!) {
    getProductById(productId: $productId) {
      isCreated
      product {
        id
        name
        image
        ingredientDocument
        description
        isDisable
        attribute {
          id
          name
          description
        }
        variations {
          id
          uid
          name
          searchableName
          distributorPrice
          retailerPrice
          jobberPrice
          images
          isDefault
          isDisable
          ingredientDocument
        }
      }
    }
  }
`;

export const GET_ATTRIBUTES_LIST = gql`
  query getAllAttributes($filters: FilterDataInput) {
    getAllAttributes(filters: $filters) {
      attributes {
        id
        name
      }
    }
  }
`;

export const GET_RESTRICTED_STATES = gql`
  query getRestrictedStates($subCategoryId: String!) {
    getRestrictedStates(subCategoryId: $subCategoryId) {
      restrictedStates
    }
  }
`;

export const CHECK_IF_UID_EXIST = gql`
  query checkIfProductUIDExist($uid: String!) {
    checkIfProductUIDExist(uid: $uid)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
