import client from '../../apollo';
import {
  ADD_FEATURE_PRODUCT,
  DELETE_FEATURE_PRODUCT,
  REARRANGE_FEATURE_PRODUCTS
} from './graphql/mutation';
import { GET_ENABLED_PRODUCTS, GET_FEATURED_PRODUCTS } from './graphql/query';

export const getFeatureProducts = async () => {
  try {
    const response = await client.query({
      query: GET_FEATURED_PRODUCTS,
      fetchPolicy: 'network-only'
    });
    return response?.data?.getFeatureProducts;
  } catch (error) {
    return [];
  }
};

export const removeFeatureProduct = async (productId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_FEATURE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { productId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const getEnableProducts = async (query) => {
  try {
    const response = await client.query({
      query: GET_ENABLED_PRODUCTS,
      fetchPolicy: 'network-only',
      variables: {
        query
      }
    });
    return response?.data?.getEnableProducts;
  } catch (error) {
    return [];
  }
};

export const addFeatureProduct = async (productId) => {
  try {
    const response = await client.mutate({
      mutation: ADD_FEATURE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { productId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const rearrangeFeatureProduct = async (productsId) => {
  try {
    const response = await client.mutate({
      mutation: REARRANGE_FEATURE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables: { productsId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
