import { Avatar, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../../../common/components/CommonTable';
import LoaderComponent from '../../../../../../common/components/LoaderComponent';
import {
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../../../../../common/constants';
import { getRejectedDistributorRequests } from '../../../../distributors-repository';

export function RejectedRequests() {
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;
  // STATES
  const [rejectedDistributors, setRejectedDistributors] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [, setCurrentGlobalSkip] = useState(0);
  const [query] = useState('');
  const [paginationProp, setPaginationProp] = useState();

  const selectRow = (ID) => {
    const url = `${ROUTES.SINGLE_REQUEST}?distributorId=${ID}`;
    // eslint-disable-next-line no-undef
    navigate(url);
  };

  const fetchRejectedDistributors = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getRejectedDistributorRequests({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setRejectedDistributors(response.distributors.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchRejectedDistributors({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'sno',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'COMPANY NAME',
      dataIndex: 'companyName',
      key: '3'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email',
      key: '4'
    },
    {
      title: 'BUSINESS POC',
      dataIndex: 'businessTelephoneNumber',
      key: '5'
    },
    {
      title: 'MOBILE NUMBER (POC)',
      dataIndex: 'mobileNumber',
      key: '6'
    },
    {
      title: '',
      dataIndex: 'name',
      key: '7',
      render: (value, record) => {
        return (
          <div className="docs-content">
            <Avatar
              src={record?.documents[0]?.url}
              shape="square"
              size="default"
            />
            <Avatar
              src={record?.documents[1]?.url}
              shape="square"
              size="default"
            />
            <Avatar
              src={record?.documents[2]?.url}
              shape="square"
              size="default"
            />
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'name',
      key: '8',
      render: (value, record) => {
        return (
          <div className="btns-section-request">
            <Button
              onClick={() => {
                selectRow(record.id);
              }}
            >
              REVIEW
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="pending-content">
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          data={rejectedDistributors}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          onChange={(onChange) => {
            setLoadingData(true);
            fetchRejectedDistributors({
              currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
          }}
        />
      )}
    </div>
  );
}
