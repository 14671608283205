/* eslint-disable no-nested-ternary */
import { Avatar, Divider, Empty, Form, Modal, Tag, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';

import moment from 'moment';

import TextArea from 'antd/es/input/TextArea';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as OrderEye } from '../../../../assets/other-icons/orderEye.svg';
import { ReactComponent as Dline1 } from '../../../../assets/timeline-icons/darkLine1.svg';
import { ReactComponent as DLine2 } from '../../../../assets/timeline-icons/darkLine2.svg';
import { ReactComponent as Done1 } from '../../../../assets/timeline-icons/done1.svg';
import { ReactComponent as Done2 } from '../../../../assets/timeline-icons/done2.svg';
import { ReactComponent as Done3 } from '../../../../assets/timeline-icons/done3.svg';
import { ReactComponent as Lline1 } from '../../../../assets/timeline-icons/lightLine1.svg';
import { ReactComponent as Lline2 } from '../../../../assets/timeline-icons/lightLine2.svg';
import { ReactComponent as Undone2 } from '../../../../assets/timeline-icons/undone2.svg';
import { ReactComponent as Undone3 } from '../../../../assets/timeline-icons/undone3.svg';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../../../common/constants';
import { getOrderById } from '../../../orders/orders-repository';
import { getOrdersBelongToDistributor } from '../../distributors-repository';

export function SingleCustomer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [activeOrders] = Form.useForm();
  // MODAL
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [orderID, setOrderID] = useState('');
  const [, setCurrentGlobalSkip] = useState(0);
  const [activeRequests, setActiveRequests] = useState([]);
  const [singleOrder, setSingleOrder] = useState([]);
  const [reqProducts, setReqProducts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState();

  const fetchActiveOrders = async ({ currentSkip }) => {
    const distributorId = searchParams.get('customerId');
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOrdersBelongToDistributor(
        {
          skip: currentSkip,
          limit: limit,
          query: ''
        },
        distributorId
      );
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveRequests(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchActiveOrders({
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openOrderModalData = async (ID) => {
    setLoadingData(true);
    const response = await getOrderById(ID);
    setSingleOrder(response.order);
    setReqProducts(response?.order?.orderSummary?.products.slice());
    activeOrders.setFieldValue('note', response?.order?.note);
    setIsCreateModal(true);
    setLoadingData(false);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1'
    },
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: '2'
    },
    {
      title: 'RECIPIENT NAME',
      dataIndex: 'serialNumber',
      key: '3'
      // render: (value, record) => {
      //   return record?.distributor?.name;
      // }
    },
    {
      title: 'NO. OF ITEMS',
      dataIndex: 'serialNumber',
      key: '4'
      // render: (value, record) => {
      //   return record.items.length;
      // }
    },
    {
      title: 'PLACED ON',
      dataIndex: 'createdAt',
      key: '5',
      render: (value) => {
        return (
          <div>
            {moment(value).format('DD/MM/YYYY')} | {moment(value).format('LT')}
          </div>
        );
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `$ ${value.toFixed(2)}`;
      }
    },
    {
      title: 'IS PAID',
      dataIndex: 'isPaid',
      key: '7',
      render: (value) => {
        return value ? (
          <Tag className="paid-tag">YES</Tag>
        ) : (
          <Tag className="pending-tag">PENDING</Tag>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'orderStatus',
      key: '8',
      render: (value) => {
        return (
          <Tag
            className={
              value === 'DELIVERED'
                ? 'paid-tag delivered-tag'
                : value === 'SHIPPED'
                ? 'paid-tag shipped-tag'
                : value === 'APPROVED'
                ? 'paid-tag delivered-tag'
                : value === 'PLACED'
                ? 'paid-tag placed-tag'
                : 'paid-tag rejected-tag'
            }
          >
            {value}
          </Tag>
        );
      }
    },

    {
      title: '',
      dataIndex: 'orderId',
      key: '9',
      render: (value, record) => {
        return (
          <OrderEye
            onClick={() => {
              setOrderID(value);
              openOrderModalData(record.id);
            }}
            className="edit-icon "
          />
        );
      }
    }
  ];

  const handleCancelCreate = () => {
    setIsCreateModal(false);
  };
  const timeLine = () => {
    if (singleOrder?.orderStatus === 'APPROVED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone2 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone3 />
          </section>
          <section>
            <Lline1 />
          </section>
        </>
      );
    }
    if (singleOrder?.orderStatus === 'SHIPPED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done2 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone3 />
          </section>
          <section>
            <Lline1 />
          </section>
        </>
      );
    }
    if (singleOrder?.orderStatus === 'DELIVERED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done2 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done3 />
          </section>
          <section>
            <Dline1 />
          </section>
        </>
      );
    }
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={orderID}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content ">
          <div className="timeline">{timeLine()}</div>
          <div className="products-section orders-prod-list">
            {reqProducts.length < 1 ? (
              <Empty />
            ) : (
              reqProducts.map((prod) => {
                return (
                  <div
                    className="product-card orders-products"
                    key={prod.purchasedVariant.variantId}
                  >
                    <div className="sect">
                      <div className="avatar-section">
                        <Avatar
                          src={prod.purchasedVariant.image}
                          size={60}
                          shape="square"
                        />
                      </div>
                      <div className="title-section">
                        {prod.title} &nbsp; x ({prod.purchasedVariant.qty})
                      </div>
                    </div>
                    <div className="price-sect">
                      $ {prod.purchasedVariant.price.toFixed(2)}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <Divider />
          <div className="note-area">
            <section className="note-title">NOTE</section>
            <Form layout="vertical" form={activeOrders}>
              <Form.Item name="note">
                <TextArea
                  autoSize={{
                    minRows: 4,
                    maxRows: 4
                  }}
                  type="text"
                  disabled
                  placeholder="Enter note"
                />
              </Form.Item>
            </Form>
          </div>
          <div className="shipping-area">
            <section>SHIPPING CHARGES</section>
            <section>${singleOrder?.shippingCharges?.toFixed(2)}</section>
          </div>
          <Divider />
          <div className="total-area">
            <section>TOTAL</section>
            <section>${singleOrder?.subTotal?.toFixed(2)}</section>
          </div>
        </div>
      </Modal>
      <Header className="main-header req-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate('/')} />
          SINGLE DISTRIBUTOR
        </Typography.Title>
      </Header>

      <div className="table-section single-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowClassName={(record) =>
              record.isDisabled ? 'disabled-row' : null
            }
            columns={columns}
            data={activeRequests}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchActiveOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
