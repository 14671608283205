/* eslint-disable import/no-extraneous-dependencies */
import { Button, Divider, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import './invoice.less';

import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as BackBtn } from '../../assets/other-icons/backBtn.svg';
import LoaderComponent from '../../common/components/LoaderComponent';
import { getOrderById } from '../orders/orders-repository';

import logoImg from '../../assets/logo/logo.jpg';

export function OrderInvoice() {
  const navigate = useNavigate();
  // STATES
  const [singleOrder, setSingleOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const componentRef = useRef();

  const fetchSingleOrder = async () => {
    const orderId = searchParams.get('orderId');
    const response = await getOrderById(orderId);
    setSingleOrder(response.order);
    try {
      // const
    } catch (error) {
      //
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSingleOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div className="distribution-content">
      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate('/orders')} />
          {singleOrder?.orderId || 'ORDER-ID'}
        </Typography.Title>
        <Button onClick={handlePrint} className="print-invoice-btn">
          PRINT ORDER
        </Button>
      </Header>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div ref={componentRef} className="invoice-content">
          <div className="invoice">
            <div className="logo">
              <img src={logoImg} alt="a1-logo" className="invoice-logo" />
              <div className="address-bar">
                <span>A-1 Wholesale Inc.</span>
                <span>2605 Mountain Industrial Blvd Suite 1</span>
                <span>Tucker GA 30084</span>
                <span>+1 (770) 674-7875</span>
                <span>admin@a1wholesaleusa.com</span>
                <span>https://a1wholesaleusa.com</span>
              </div>
            </div>
            <Divider dashed className="invoice-dash" />
            <div className="invoice-info">
              <div className="left-info">
                <span>
                  <b>INVOICE NO</b> : {singleOrder?.orderId}
                </span>
                <span>
                  <b>ORDER ID </b>: {singleOrder?.id}
                </span>
              </div>
              <div className="right-info">
                <span>
                  <b>NAME</b> : {singleOrder?.distributor?.name}
                </span>
                <span>
                  <b>DATE</b> : {dayjs(Date()).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
            <Divider dashed className="invoice-dash" />
            <div className="invoice-address">
              <div className="left-address">
                <b> BILLING ADDRESS</b>
                <span>
                  {singleOrder?.billingAddress?.addressLine1}&nbsp;
                  {singleOrder?.billingAddress?.addressLine2}&nbsp;
                  {singleOrder?.billingAddress?.city}&nbsp;
                  {singleOrder?.billingAddress?.state}&nbsp;
                  {singleOrder?.billingAddress?.country}&nbsp;
                  {singleOrder?.billingAddress?.zipCode}
                </span>
              </div>
              <div className="right-address">
                <b> SHIPPING ADDRESS</b>
                <span>
                  {singleOrder?.address?.addressLine1}&nbsp;
                  {singleOrder?.address?.addressLine2}&nbsp;
                  {singleOrder?.address?.city}&nbsp;
                  {singleOrder?.address?.state}&nbsp;
                  {singleOrder?.address?.country}&nbsp;
                  {singleOrder?.address?.zipCode}
                </span>
              </div>
            </div>
            <Divider dashed className="invoice-dash" />
            <div className="invoice-table">
              <table
                style={{
                  borderCollapse: 'collapse',
                  width: '100%',
                  fontFamily: 'Arial, sans-serif'
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: '#f5f5f5' }}>
                    <th
                      style={{
                        width: '10px',
                        padding: '12px'
                      }}
                      align="left"
                    >
                      OR.NO
                    </th>
                    <th
                      style={{
                        width: '200px',
                        padding: '12px'
                      }}
                      align="left"
                    >
                      DESCRIPTION
                    </th>
                    <th
                      style={{
                        padding: '12px'
                      }}
                      align="left"
                    >
                      SKU
                    </th>
                    <th
                      style={{
                        padding: '12px'
                      }}
                      align="left"
                    >
                      QTY
                    </th>
                    <th
                      style={{
                        padding: '12px',
                        width: '60px'
                      }}
                      align="left"
                    >
                      UNIT PRICE
                    </th>
                    <th
                      style={{
                        padding: '12px',
                        width: '60px'
                      }}
                      align="left"
                    >
                      AMOUNT
                    </th>
                    <th align="left" style={{ textAlign: 'center' }}>
                      CHECK
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singleOrder?.orderSummary?.products.map((prod, index) => {
                    return (
                      <tr key={prod.variantId}>
                        <td
                          style={{
                            width: '10px',
                            padding: '12px'
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            width: '200px',
                            padding: '12px'
                          }}
                        >
                          {prod.title} - {prod.purchasedVariant.title}
                        </td>
                        <td
                          style={{
                            padding: '12px',
                            verticalAlign: 'top'
                          }}
                        >
                          {prod.purchasedVariant.uid}
                        </td>
                        <td
                          style={{
                            padding: '12px',
                            verticalAlign: 'top'
                          }}
                        >
                          {prod.purchasedVariant.qty}
                        </td>
                        <td
                          style={{
                            padding: '12px',
                            width: '60px',
                            verticalAlign: 'top'
                          }}
                        >
                          {prod.purchasedVariant.price.toFixed(2)}
                        </td>
                        <td
                          style={{
                            padding: '12px',
                            width: '60px',
                            verticalAlign: 'top'
                          }}
                        >
                          {(
                            prod.purchasedVariant.price *
                            prod.purchasedVariant.qty
                          ).toFixed(2)}
                        </td>
                        <td />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Divider dashed className="invoice-dash" />
            <div className="invoice-pricing">
              <div className="pricing-content">
                <div className="pricing-tag">
                  <span>
                    <b>TOTAL PRICE:</b>
                  </span>
                  <span>
                    <b> SHIPPING PRICE:</b>
                  </span>
                  <span>
                    <b>SUB-TOTAL PRICE:</b>
                  </span>
                  <span>
                    <b> PAID PRICE:</b>
                  </span>
                </div>
                <div className="pricing-amt">
                  <span style={{ textAlign: 'right' }}>
                    &nbsp;$ {singleOrder?.orderSummary?.total.toFixed(2)}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    &nbsp;$ {singleOrder?.shippingCharges.toFixed(2)}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    &nbsp;$ {singleOrder?.orderSummary?.subTotal.toFixed(2)}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    &nbsp;
                    {singleOrder?.isPaid ? 'PAID' : 'NOT PAID'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
