import { gql } from '@apollo/client';

export const GET_TOTAL_PENDING_ORDERS_DISTRIBUTOR = gql`
  query {
    getTotalPendingOrdersDistributor {
      totalPendingDistributorRequests
      totalPendingOrders
    }
  }
`;
