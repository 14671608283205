import { gql } from '@apollo/client';

export const TOGGLE_ENABLE_DISTRIBUTOR = gql`
  mutation toggleEnableDistributor($distributorId: String!) {
    toggleEnableDistributor(distributorId: $distributorId)
  }
`;

export const REJECT_DISTRIBUTOR = gql`
  mutation rejectDistributorRequest(
    $distributorId: String!
    $rejectionComment: String!
  ) {
    rejectDistributorRequest(
      distributorId: $distributorId
      rejectionComment: $rejectionComment
    )
  }
`;

export const APPROVE_DISTRIBUTOR = gql`
  mutation approveDistributorRequest(
    $distributorId: String!
    $distributorType: DistributorType!
  ) {
    approveDistributorRequest(
      distributorId: $distributorId
      distributorType: $distributorType
    )
  }
`;

export const UPDATE_DISTRIBUTOR = gql`
  mutation updateDistributor(
    $name: String!
    $companyName: String!
    $businessTelephoneNumber: String!
    $businessPointOfContact: String!
    $mobileNumber: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $zipCode: String!
    $documents: [DistributorDocumentsInput]
    $distributorId: String!
  ) {
    updateDistributor(
      distributorId: $distributorId
      data: {
        name: $name
        companyName: $companyName
        businessTelephoneNumber: $businessTelephoneNumber
        businessPointOfContact: $businessPointOfContact
        mobileNumber: $mobileNumber
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zipCode: $zipCode
        documents: $documents
      }
    )
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
