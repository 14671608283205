/* eslint-disable no-console */
import client from '../../apollo';
import {
  APPROVE_DISTRIBUTOR,
  REJECT_DISTRIBUTOR,
  TOGGLE_ENABLE_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR
} from './graphql/mutation';
import {
  GET_DISTRIBUTORS,
  GET_DISTRIBUTOR_BY_ID,
  GET_ORDERS_AS_PER_DISTRIBUTOR,
  GET_PENDING_DISTRIBUTORS,
  GET_REJECTED_DISTRIBUTORS
} from './graphql/query';

export const getDistributors = async (filters) => {
  const { skip, limit, query, type } = filters;
  try {
    const response = await client.query({
      query: GET_DISTRIBUTORS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          type
        }
      }
    });
    return response?.data?.getDistributors;
  } catch (error) {
    return [];
  }
};

export const getPendingDistributorRequests = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_PENDING_DISTRIBUTORS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getPendingDistributorRequests;
  } catch (error) {
    return [];
  }
};

export const getRejectedDistributorRequests = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_REJECTED_DISTRIBUTORS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getRejectedDistributorRequests;
  } catch (error) {
    return [];
  }
};

export const getDistributorById = async (distributorId) => {
  try {
    const response = await client.query({
      query: GET_DISTRIBUTOR_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        distributorId
      }
    });
    return response?.data?.getDistributorById;
  } catch (error) {
    return [];
  }
};

export const toggleEnableDistributor = async (distributorId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: {
        distributorId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const rejectDistributorRequest = async (
  distributorId,
  rejectionComment
) => {
  try {
    const response = await client.mutate({
      mutation: REJECT_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: {
        distributorId,
        rejectionComment
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const approveDistributorRequest = async (
  distributorId,
  distributorType
) => {
  try {
    const response = await client.mutate({
      mutation: APPROVE_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: {
        distributorId,
        distributorType
      }
    });

    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateDistributor = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: data
    });

    return response?.data;
  } catch (error) {
    return false;
  }
};

export const getOrdersBelongToDistributor = async (filters, distributorId) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ORDERS_AS_PER_DISTRIBUTOR,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        },
        distributorId
      }
    });
    return response?.data?.getOrdersBelongToDistributor;
  } catch (error) {
    return [];
  }
};

export const test = async () => {};
