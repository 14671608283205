import { gql } from '@apollo/client';

export const CHANGE_STATUS = gql`
  mutation changeActiveOrderStatus(
    $orderStatus: ActiveOrderStatus!
    $orderId: String!
    $optionalData: ChangeActiveOrderStatusOptionalInput
  ) {
    changeActiveOrderStatus(
      orderStatus: $orderStatus
      orderId: $orderId
      optionalData: $optionalData
    )
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($data: ApproveOrderInput!) {
    approveOrder(data: $data)
  }
`;

export const REJECT_ORDER = gql`
  mutation rejectOrder($data: RejectOrderInput!) {
    rejectOrder(data: $data)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
