import { Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import SearchComponent from '../../../../common/SearchDebounce';
import { PendingOrders } from './pending-orders/PendingOrders';
import { RejectedOrders } from './rejected-orders/RejectedOrders';

export function OrderRequests() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  return (
    <div className="distribution-content">
      <Header className="main-header">
        <Typography.Title className="header-title orders-header">
          <BackBtn
            className="back-btn order-back"
            onClick={() => navigate(-1)}
          />{' '}
          <span className="request-header-title">ORDERS/</span>REQUESTS
        </Typography.Title>
      </Header>
      <div className="order-content">
        <div className="search-section orders-search">
          <div className="search">
            <SearchComponent
              name="Orders"
              debounceTime={1}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </div>
        </div>
        <div className="order-tabs">
          <Tabs
            items={[
              {
                key: '1',
                label: `PENDING `,
                children: <PendingOrders query={query} />
              },
              {
                key: '2',
                label: `REJECTED `,
                children: <RejectedOrders query={query} />
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
