/* eslint-disable no-console */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Typography,
  message
} from 'antd';

import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import './sub-categories.less';

import { LockFilled, QuestionCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as DeleteBtn } from '../../../../assets/other-icons/deletebtn.svg';
import { ReactComponent as EditIcon } from '../../../../assets/other-icons/edit.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/other-icons/viewPageEye.svg';
import SearchComponent from '../../../../common/SearchDebounce';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import {
  GLOBAL_PAGINATION_LIMIT,
  ROUTES_HELPER,
  US_STATES_CODES
} from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import {
  addRestrictedStates,
  createSubCategory,
  deleteSubCategory,
  getRestrictedStates,
  getSubCategories,
  updateSubCategory
} from '../../categories-repository';

export function SubCategories({ user }) {
  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  const { cat_id: catId } = useParams();
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [createForm] = Form.useForm();
  const { required } = formValidatorRules;

  // STATES
  const [subCategories, setSubCategories] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [paginationProp, setPaginationProp] = useState();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isRestrictedStateModal, setIsRestrictedStateModal] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [selectedCodes, setSelectedCodes] = useState();
  const { Option } = Select;

  const fetchSubCategories = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getSubCategories(
        {
          skip: currentSkip,
          limit: limit,
          query: currentQuery
        },
        catId
      );
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setSubCategories(response.subCategories.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchSubCategories({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    createForm.resetFields();
    setIsUpdateModal(false);
    setIsCreateModal(false);
  };

  const handleCancelRestrictedState = () => {
    setSelectedCodes([]);
    setSelectedId('');
    setIsRestrictedStateModal(false);
  };

  const subCategoryUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateModal(true);
    setTimeout(() => {
      setIsCreateModal(true);
      setLoadingData(false);
      createForm.setFieldsValue({
        name: record.name,
        description: record.description
      });
    }, 500);
  };

  const selectRow = (ID) => {
    // const url = `/${ROUTES_HELPER.CATEGORIES}/${ID}`;
    // eslint-disable-next-line no-undef, no-restricted-globals
    const url = `${location.pathname}/${ID}/${ROUTES_HELPER.PRODUCTS}`;
    navigate(url);
  };

  const rearrangeDirect = () => {
    // eslint-disable-next-line no-restricted-globals, no-undef
    const url = `${location.pathname}/rearrange`;
    navigate(url);
  };

  const deleteSelectedSubCategory = async (index, ID) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Deleting sub category'
    });
    await deleteSubCategory(ID);
    const delSubCategory = subCategories.slice();
    delSubCategory.splice(index, 1);
    message.open({
      key,
      type: 'success',
      content: 'Deleted'
    });
    setSubCategories(delSubCategory);
  };

  const isRestrictedState = async (ID) => {
    setLoading(true);
    const res = await getRestrictedStates(ID);
    setSelectedCodes(res);
    setTimeout(() => {
      setIsRestrictedStateModal(true);
      setSelectedId(ID);
      console.log(selectedCodes);
    }, 500);
    setLoading(false);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'PRODUCTS',
      dataIndex: 'name',
      key: '3',
      render: (value, record) => {
        return record?.products?.length;
      }
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: '4'
    },

    {
      title: '',
      dataIndex: 'isDisabled',
      key: '8',
      render: (value, record, index) => {
        if (isManager) {
          return (
            <div className="btns-section">
              <ViewIcon
                className="edit-icon"
                onClick={() => selectRow(record.id, record.isDisabled)}
              />
            </div>
          );
        }
        return (
          <div className="btns-section">
            <ViewIcon
              className="edit-icon"
              onClick={() => selectRow(record.id, record.isDisabled)}
            />
            <EditIcon
              className="edit-icon"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  subCategoryUpdate(index, value, record);
                }, 200);
              }}
            />
            <Popconfirm
              title="DELETE SUB CATEGORY"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                    width: '10px'
                  }}
                />
              }
              description="ARE YOU SURE YOU WANT TO DELETE THIS SUB CATEGORY"
              onConfirm={() => deleteSelectedSubCategory(index, record.id)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
            <LockFilled
              onClick={() => isRestrictedState(record.id)}
              className="restricted-icon"
            />
          </div>
        );
      }
    }
  ];

  const handleChange = (value) => {
    console.log(value);
    setSelectedCodes(value);
  };

  const submitCreateForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const createNewSubCategory = await createSubCategory(
      {
        name,
        description
      },
      catId
    );
    if (
      subCategories.length === 0 ||
      subCategories.length < limit ||
      subCategories[subCategories.length - 1].serialNumber + 1 ===
        createNewSubCategory.serialNumber
    ) {
      const updatedSubCategories = subCategories.slice();
      updatedSubCategories.push(createNewSubCategory);
      setSubCategories(updatedSubCategories.slice());
    }
    createForm.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    fetchSubCategories({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  const submitUpdatedForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const updateSubCreatedCategory = await updateSubCategory({
      name,
      description,
      subCategoryId: selectedId
    });
    const updatedSubCategories = subCategories.slice();
    updatedSubCategories[currentSelectedIndex] = updateSubCreatedCategory;
    setSubCategories(updatedSubCategories);
    createForm?.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    setIsUpdateModal(false);
  };

  const submitRestrictedStates = async () => {
    if (selectedCodes.length === 0 && selectedCodes.length === 0) {
      message.error('Atleaset 1 state needed');
      return;
    }
    setIsSavingData(true);
    await addRestrictedStates(selectedCodes, selectedId);
    setIsSavingData(false);
    setSelectedCodes([]);
    setIsRestrictedStateModal(false);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={isUpdateModal ? 'UPDATE SUB CATEGORY' : 'NEW SUB CATEGORY'}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={isUpdateModal ? submitUpdatedForm : submitCreateForm}
          >
            <Form.Item label="Name" name="name" rules={[required]}>
              <Input id="attribute-inp" placeholder="Enter sub category name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[required]}
            >
              <TextArea
                autoSize={{
                  minRows: 5,
                  maxRows: 5
                }}
                type="text"
                placeholder="Enter description"
              />
            </Form.Item>
            <Button loading={isSavingData} id="create-btn" htmlType="submit">
              {isUpdateModal ? 'UPDATE SUB CATEGORY' : 'ADD SUB CATEGORY'}
            </Button>
          </Form>
        </div>
      </Modal>
      {/* RESTRICTED STATE MODAL */}
      <Modal
        title="ADD RESTRICTED STATE"
        open={isRestrictedStateModal}
        onCancel={handleCancelRestrictedState}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <Select
          mode="multiple"
          placeholder="Select restricted states"
          onChange={handleChange}
          optionLabelProp="label"
          className="restricted-state-select"
          allowClear
          value={selectedCodes}
        >
          {US_STATES_CODES.map((state) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Option
                value={state.code}
                searchValue={state.name}
                label={state.name}
              >
                {state.name}
              </Option>
            );
          })}
        </Select>
        <div className="modal-content">
          <Button
            loading={isSavingData}
            onClick={() => submitRestrictedStates()}
            id="create-btn"
          >
            ADD RESTRICTED STATE
          </Button>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn
            className="back-btn"
            onClick={() => navigate('/categories')}
          />
          <span className="request-header-title">CATEGORIES/</span>SUB
          CATEGORIES
        </Typography.Title>
        <div className="btn-header-section">
          <Button onClick={() => setIsCreateModal(true)}>
            ADD SUB-CATEGORY
          </Button>
          <Button onClick={rearrangeDirect}>RE-ARRANGE LIST</Button>
        </div>
      </Header>
      <div className="categories-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Sub Category"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchSubCategories({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>
        </div>
        <div className="table-section categories-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              rowClassName={(record) =>
                record.isDisable ? 'disabled-row' : null
              }
              columns={columns}
              data={subCategories}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchSubCategories({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
