/* eslint-disable no-console */
import client from '../../apollo';
import {
  ADD_RESTRICTED_STATES,
  BULK_VALUE_UPDATE,
  CREATE_BULK_VARIATIONS,
  CREATE_CATEGORY,
  CREATE_PRODUCT,
  CREATE_SUB_CATEGORY,
  CREATE_VARIANT,
  DELETE_CATEGORY,
  DELETE_PRODUCT,
  DELETE_SUB_CATEGORY,
  DELETE_VARIATION,
  REARRANGE_PRODUCTS,
  REARRANGE_SUB_CATEGORIES,
  TOGGLE_DEFAULT,
  TOGGLE_ENABLE_DISABLE_PRODUCT,
  TOGGLE_ENABLE_DISABLE_VARIATION,
  TOGGLE_FEATURE_CATEGORY,
  TOGGLE_VISIBILITY_CATEGORY,
  UPDATE_BULK_VARIATIONS,
  UPDATE_CATEGORY,
  UPDATE_PRODUCT,
  UPDATE_SUB_CATEGORY,
  UPDATE_VARIATION,
  UPDATE_VARIATION_FIRST_IMAGE,
  UPLOAD_PRODUCT_PDF,
  UPLOAD_PRODUCT_PHOTO,
  UPLOAD_VARIATION_IMAGES
} from './graphql/mutation';
import {
  CHECK_IF_UID_EXIST,
  GET_ALL_CATEGORIES,
  GET_ATTRIBUTES_LIST,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_RESTRICTED_STATES,
  GET_SUB_CATEGORIES
} from './graphql/query';

export const getAllCategories = async (filters) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getAllCategories;
  } catch (error) {
    return [];
  }
};

export const createCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { data }
    });

    return response?.data?.createCategory?.category;
  } catch (error) {
    return false;
  }
};

export const updateCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateCategory?.category;
  } catch (error) {
    return false;
  }
};

export const createSubCategory = async (data, categoryId) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_SUB_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { data, categoryId }
    });

    return response?.data?.createSubCategory?.subCategory;
  } catch (error) {
    return false;
  }
};

export const getSubCategories = async (filters, categoryId) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_SUB_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        },
        categoryId
      }
    });
    return response?.data?.getSubCategories;
  } catch (error) {
    return [];
  }
};

export const updateSubCategory = async (data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_SUB_CATEGORY,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.updateSubCategory?.subCategory;
  } catch (error) {
    return false;
  }
};

export const getProducts = async (filters, subCategoryId) => {
  const { skip, limit, query } = filters;
  try {
    const response = await client.query({
      query: GET_PRODUCTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        },
        subCategoryId
      }
    });
    return response?.data?.getProducts;
  } catch (error) {
    return [];
  }
};

export const getProductById = async (productId) => {
  try {
    const response = await client.query({
      query: GET_PRODUCT_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        productId
      }
    });
    return response?.data?.getProductById;
  } catch (error) {
    return false;
  }
};
export const bulkValueUpdate = async (data, productId) => {
  try {
    const response = await client.mutate({
      mutation: BULK_VALUE_UPDATE,
      fetchPolicy: 'network-only',
      variables: { data, productId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const updateProduct = async (data, productId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { data, productId }
    });
    return response?.data?.updateProduct;
  } catch (error) {
    return false;
  }
};

export const createProduct = async (data, subCategoryId) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { data, subCategoryId }
    });

    return response?.data?.createProduct?.product;
  } catch (error) {
    return false;
  }
};

export const getAllAttributes = async () => {
  try {
    const response = await client.query({
      query: GET_ATTRIBUTES_LIST,
      fetchPolicy: 'network-only',
      variables: {
        filters: {}
      }
    });
    return response?.data?.getAllAttributes;
  } catch (error) {
    return [];
  }
};

export const uploadVariationImage = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_VARIATION_IMAGES,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadVariationImage;
  } catch (error) {
    return '';
  }
};

export const createVariation = async (data, productId) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_VARIANT,
      fetchPolicy: 'network-only',
      variables: { data, productId }
    });

    return response?.data?.createVariation?.variation;
  } catch (error) {
    return false;
  }
};

export const updateVariation = async (data, variationId) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_VARIATION,
      fetchPolicy: 'network-only',
      variables: { data, variationId }
    });
    return response?.data?.updateVariation?.variation;
  } catch (error) {
    return false;
  }
};

export const toggleDefaultVariation = async (variationId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_DEFAULT,
      fetchPolicy: 'network-only',
      variables: { variationId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const deleteVariation = async (variationId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_VARIATION,
      fetchPolicy: 'network-only',
      variables: { variationId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { categoryId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const deleteSubCategory = async (subCategoryId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_SUB_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { subCategoryId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { productId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const toggleFeatureCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_FEATURE_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { categoryId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const getRestrictedStates = async (subCategoryId) => {
  try {
    const response = await client.query({
      query: GET_RESTRICTED_STATES,
      fetchPolicy: 'network-only',
      variables: {
        subCategoryId
      }
    });
    return response?.data?.getRestrictedStates?.restrictedStates;
  } catch (error) {
    return [];
  }
};

export const addRestrictedStates = async (states, subCategoryId) => {
  try {
    const response = await client.mutate({
      mutation: ADD_RESTRICTED_STATES,
      fetchPolicy: 'network-only',
      variables: { states, subCategoryId }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const checkIfProductUIDExist = async (uid) => {
  try {
    const response = await client.query({
      query: CHECK_IF_UID_EXIST,
      fetchPolicy: 'network-only',
      variables: { uid }
    });
    return response?.data?.checkIfProductUIDExist;
  } catch (error) {
    return false;
  }
};

export const uploadProductImage = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_PRODUCT_PHOTO,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadProductImage;
  } catch (error) {
    return '';
  }
};

export const uploadProductPDF = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_PRODUCT_PDF,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadProductPDF;
  } catch (error) {
    return '';
  }
};

export const createVariationInBulk = async (variationsData, productId) => {
  try {
    await client.mutate({
      mutation: CREATE_BULK_VARIATIONS,
      fetchPolicy: 'network-only',
      variables: { variationsData, productId }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const toggleDisableVariation = async (variationId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_DISABLE_VARIATION,
      fetchPolicy: 'network-only',
      variables: { variationId }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const toggleDisableProduct = async (productId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_DISABLE_PRODUCT,
      fetchPolicy: 'network-only',
      variables: { productId }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateVariationFirstImage = async (variationId, imgUrl) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_VARIATION_FIRST_IMAGE,
      fetchPolicy: 'network-only',
      variables: { variationId, imgUrl }
    });
    return response?.data?.updateVariationFirstImage;
  } catch (error) {
    return false;
  }
};

export const updateVariationInBulk = async (variationsData, productId) => {
  console.log(variationsData[0]);
  try {
    await client.mutate({
      mutation: UPDATE_BULK_VARIATIONS,
      fetchPolicy: 'network-only',
      variables: { variationsData, productId }
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const toggleVisibilityCategory = async (categoryId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_VISIBILITY_CATEGORY,
      fetchPolicy: 'network-only',
      variables: { categoryId }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const rearrangeSubCategories = async (Ids) => {
  try {
    const response = await client.mutate({
      mutation: REARRANGE_SUB_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: { subCategoryIds: Ids }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const rearrangeProducts = async (Ids) => {
  try {
    const response = await client.mutate({
      mutation: REARRANGE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables: { productIds: Ids }
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const test = async () => {};
