/* eslint-disable no-console */
import { Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../common/SearchDebounce';
import CommonTable from '../../common/components/CommonTable';

import { ReactComponent as ViewIcon } from '../../assets/other-icons/viewPageEye.svg';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { getProducts } from '../categories/categories-repository';

export function AllProducts() {
  const navigate = useNavigate();

  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const [paginationProp, setPaginationProp] = useState();

  const fetchProducts = async ({ currentSkip, currentQuery }) => {
    try {
      const response = await getProducts(
        {
          skip: currentSkip,
          limit: limit,
          query: currentQuery
        },
        null
      );
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setProducts(response.products.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchProducts({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRow = (data) => {
    const { id, categoryData, subCategoryData } = data;
    const url = `/categories/${categoryData.id}/sub-categories/${subCategoryData.id}/products/${id}`;
    navigate(url);
    console.log(data);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'ATTRIBUTE',
      dataIndex: 'name',
      key: '3',
      render: (value, record) => {
        return record?.attribute?.name;
      }
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: '4'
    },

    {
      title: '',
      dataIndex: 'name',
      key: '8',
      render: (value, record) => {
        return (
          <div className="btns-section">
            <ViewIcon className="edit-icon" onClick={() => selectRow(record)} />
          </div>
        );
      }
    }
  ];

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}

      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          ALL PRODUCTS
        </Typography.Title>
      </Header>
      <div className="categories-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Product"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchProducts({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>
        </div>
        <div className="table-section categories-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              data={products}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchProducts({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
