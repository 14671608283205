import { gql } from '@apollo/client';

export const GET_FEATURED_PRODUCTS = gql`
  query getFeatureProducts {
    getFeatureProducts {
      products {
        id
        name
        description
        isDisable
        isFeature
        popularity
        attribute {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_ENABLED_PRODUCTS = gql`
  query getEnableProducts($query: String!) {
    getEnableProducts(query: $query) {
      products {
        id
        name
        description
        isDisable
        isFeature
        popularity
        attribute {
          id
          name
          description
        }
        defaultVariation {
          id
          uid
          name
          images
        }
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
