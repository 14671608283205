import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import { AllProducts } from '../modules/all-products/AllProducts';
import { Attributes } from '../modules/attributes/Attributes';
import { Categories } from '../modules/categories/Categories';
import { Products } from '../modules/categories/components/products/Products';
import RearrangeProducts from '../modules/categories/components/products/components/RearrangeProducts';
import { SingleProduct } from '../modules/categories/components/single-product/SingleProduct';
import { SubCategories } from '../modules/categories/components/sub-categories/SubCategories';
import RearrangeSubCategories from '../modules/categories/components/sub-categories/components/RearrangeSubCategories';
import { Configurations } from '../modules/configurations/Configurations';
import { Distributors } from '../modules/distributors/Distributors';
import { Requests } from '../modules/distributors/components/requests/Requests';
import { SingleCustomer } from '../modules/distributors/components/single-customer/SingleCustomer';
import { SingleRequest } from '../modules/distributors/components/single-request/SingleRequest';
import { EditInfo } from '../modules/distributors/components/single-request/edit-info/EditInfo';
import { FeaturedProducts } from '../modules/featured-products/FeaturedProducts';
import { OrderInvoice } from '../modules/invoice/OrderInvoice';
import { Orders } from '../modules/orders/Orders';
import { OrderRequests } from '../modules/orders/components/order-requests/OrderRequests';

// eslint-disable-next-line no-unused-vars
function ContentRoutes({ user }) {
  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  if (isManager) {
    return (
      <Routes>
        <Route exact path={ROUTES.MAIN} element={<Orders user={user} />} />
        <Route exact path={ROUTES.ORDER_REQUESTS} element={<OrderRequests />} />
        <Route
          exact
          path={ROUTES.CATEGORIES}
          element={<Categories user={user} />}
        />
        <Route
          exact
          path={ROUTES.SUB_CATEGORIES}
          element={<SubCategories user={user} />}
        />
        <Route
          exact
          path={ROUTES.REARRANGE_SUB_CATEGORIES}
          element={<RearrangeSubCategories user={user} />}
        />
        <Route
          exact
          path={ROUTES.PRODUCTS}
          element={<Products user={user} />}
        />
        <Route
          exact
          path={ROUTES.SINGLE_PRODUCT}
          element={<SingleProduct user={user} />}
        />
        <Route exact path={ROUTES.ALL_PRODUCTS} element={<AllProducts />} />
        <Route
          exact
          path={ROUTES.FEATURED_PRODUCTS}
          element={<FeaturedProducts />}
        />
        <Route exact path={ROUTES.ORDER_INVOICE} element={<OrderInvoice />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route exact path={ROUTES.MAIN} element={<Distributors />} />
      <Route exact path={ROUTES.REQUESTS} element={<Requests />} />
      <Route exact path={ROUTES.SINGLE_REQUEST} element={<SingleRequest />} />
      <Route exact path={ROUTES.EDIT_INFO} element={<EditInfo />} />
      <Route exact path={ROUTES.ORDERS} element={<Orders />} />
      <Route exact path={ROUTES.ORDER_REQUESTS} element={<OrderRequests />} />
      <Route exact path={ROUTES.ATTRIBUTES} element={<Attributes />} />
      <Route exact path={ROUTES.CATEGORIES} element={<Categories />} />
      <Route exact path={ROUTES.SUB_CATEGORIES} element={<SubCategories />} />
      <Route
        exact
        path={ROUTES.REARRANGE_SUB_CATEGORIES}
        element={<RearrangeSubCategories user={user} />}
      />
      <Route exact path={ROUTES.PRODUCTS} element={<Products />} />
      <Route
        exact
        path={ROUTES.REARRANGE_PRODUCTS}
        element={<RearrangeProducts user={user} />}
      />
      <Route exact path={ROUTES.SINGLE_PRODUCT} element={<SingleProduct />} />
      <Route exact path={ROUTES.CONFIGURATIONS} element={<Configurations />} />
      <Route exact path={ROUTES.ALL_PRODUCTS} element={<AllProducts />} />
      <Route
        exact
        path={ROUTES.FEATURED_PRODUCTS}
        element={<FeaturedProducts />}
      />
      <Route exact path={ROUTES.SINGLE_CUSTOMER} element={<SingleCustomer />} />
      <Route exact path={ROUTES.ORDER_INVOICE} element={<OrderInvoice />} />
    </Routes>
  );
}

export default ContentRoutes;
