/* eslint-disable no-console */
import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../../../../assets/other-icons/backBtn.svg';
import LoaderComponent from '../../../../../common/components/LoaderComponent';
import {
  getSubCategories,
  rearrangeSubCategories
} from '../../../categories-repository';

const RowContext = createContext({});
function DragHandle() {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move'
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
}

function Row(props) {
  const { style: rowStyle, ...restProps } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key']
  });

  const style = {
    ...rowStyle,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999
        }
      : {})
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners
    }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...restProps} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
}

function RearrangeSubCategories({ user }) {
  const isManager = user?.type === 'MANAGER';

  const { cat_id: catId } = useParams();
  const navigate = useNavigate();
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const fetchSubCategories = async () => {
    try {
      const response = await getSubCategories(
        {
          skip: 0,
          limit: 100000,
          query: ''
        },
        catId
      );

      const categories = response.subCategories.map((category, index) => ({
        ...category,
        key: category.id || index // Ensure each category has a unique key
      }));

      setSubCategories(categories);
    } catch (error) {
      // Handle error
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      key: 'sort',
      align: 'center',
      width: 80,
      render: () => <DragHandle />
    },
    {
      title: '#',
      dataIndex: 'name',
      key: 'index',
      render: (value, record, index) => index + 1
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PRODUCTS',
      dataIndex: 'products',
      key: 'products',
      render: (value) => value?.length
    },
    {
      title: 'DESCRIPTION ',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  const onDragEnd = ({ active, over }) => {
    if (!over) {
      return; // Do nothing if the item is dropped outside of valid drop targets
    }
    if (active.id !== over?.id) {
      setSubCategories((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const handleSaveList = async () => {
    const mappedId = subCategories.map((category) => {
      return category.id;
    });
    try {
      setIsSaving(true);
      await rearrangeSubCategories(mappedId);
      message.success('List updated');
      setIsSaving(false);
    } catch (error) {
      message.error('Failed to update list');
      setIsSaving(false);
    }
  };

  return (
    <div className="distribution-content">
      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate(-1)} />
          <span className="request-header-title">CATEGORIES/SUB-CATEGORY/</span>
          REARRANGE
        </Typography.Title>
        <div className="btn-header-section">
          <Button loading={isSaving} onClick={handleSaveList}>
            SAVE
          </Button>
        </div>
      </Header>
      <div className="categories-section">
        <div className="table-section categories-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                items={subCategories.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  pagination={false}
                  rowKey="key"
                  components={{
                    body: {
                      row: Row
                    }
                  }}
                  columns={columns}
                  dataSource={subCategories}
                />
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </div>
  );
}

export default RearrangeSubCategories;
