import { gql } from '@apollo/client';

export const CREATE_ATTRIBUTE = gql`
  mutation createAttribute($data: CreateAttributeInput!) {
    createAttribute(data: $data) {
      isCreated
      attribute {
        id
        name
        description
      }
    }
  }
`;

export const UPDATE_ATTRIBUTE = gql`
  mutation updateAttribute(
    $name: String!
    $description: String!
    $attributeId: String!
  ) {
    updateAttribute(
      data: { name: $name, description: $description }
      attributeId: $attributeId
    ) {
      isCreated
      attribute {
        id
        name
        description
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
