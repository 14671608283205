import { gql } from '@apollo/client';

export const UPLOAD_ICON = gql`
  mutation postUploadIcon($fileName: String!) {
    uploadIcon(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation uploadDistributorDocumentAsAdmin($fileName: String!) {
    uploadDistributorDocumentAsAdmin(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($fileName: String!) {
    uploadPhoto(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
