import { Button, DatePicker, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActiveOrders } from './components/active-orders/ActiveOrders';
import { PastOrders } from './components/past-orders/PastOrders';
import './orders.less';

import { ReactComponent as OrderRequestsIcon } from '../../assets/other-icons/order-requests.svg';
import SearchComponent from '../../common/SearchDebounce';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { getOrdersCount } from './orders-repository';

export function Orders() {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [, setCurrentGlobalSkip] = useState(0);
  const [count, setCount] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  const fetchActiveOrders = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOrdersCount(
        {
          skip: currentSkip,
          limit: limit,
          query: currentQuery
        },
        'PENDING'
      );

      setCount(response.total);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveOrders({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="distribution-content">
      <Header className="main-header">
        <Typography.Title className="header-title">ORDERS</Typography.Title>
        <Button
          onClick={() => navigate('/orders/requests')}
          className="order-req-btn"
        >
          <OrderRequestsIcon /> ORDER REQUESTS: {count}
        </Button>
      </Header>

      <div className="order-content">
        <div className="orders-search">
          <div className="order-search-section">
            {' '}
            <div className="search">
              <SearchComponent
                name="Orders"
                debounceTime={1}
                getData={(change) => {
                  setQuery(change);
                }}
              />
            </div>
            <RangePicker
              allowClear
              onChange={(value) => {
                if (value) {
                  setSelectedDate({
                    startAt: dayjs(value[0]?.$d)?.format('YYYY/MM/DD'),
                    endAt: dayjs(value[1]?.$d)?.format('YYYY/MM/DD')
                  });
                } else {
                  setSelectedDate(null);
                }
              }}
            />
          </div>
        </div>
        <div className="order-tabs">
          <Tabs
            items={[
              {
                key: '1',
                label: `ACTIVE ORDERS`,
                children: (
                  <ActiveOrders query={query} selectedDate={selectedDate} />
                )
              },
              {
                key: '2',
                label: `PAST ORDERS`,
                children: (
                  <PastOrders query={query} selectedDate={selectedDate} />
                )
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
