/* eslint-disable no-console */
import { Button, Divider, Form, Input, Modal, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import './attributes.less';

import TextArea from 'antd/es/input/TextArea';
import SearchComponent from '../../common/SearchDebounce';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  createAttribute,
  getAllAttributes,
  updateAttribute
} from './attributes-repository';

import { ReactComponent as EditIcon } from '../../assets/other-icons/edit.svg';

export function Attributes() {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [createForm] = Form.useForm();
  // STATES
  const [attributes, setAttributes] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();

  const fetchAttributes = async ({ currentSkip, currentQuery }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllAttributes({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setAttributes(response.attributes.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchAttributes({
      currentQuery: '',
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    createForm.resetFields();
    setIsUpdateModal(false);
    setIsCreateModal(false);
  };

  const attributeUpdate = async (index, value, record) => {
    setLoadingData(true);
    setIsUpdateModal(true);
    setTimeout(() => {
      setIsCreateModal(true);
      setLoadingData(false);
      createForm.setFieldsValue({
        name: record.name,
        description: record.description
      });
    }, 500);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2',
      render: (value) => {
        return value;
      }
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: '3',
      render: (value) => {
        return value;
      }
    },
    {
      title: '',
      dataIndex: 'name',
      key: '3',
      render: (value, record, index) => {
        return (
          <div className="btns-section">
            <EditIcon
              className="edit-icon"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  attributeUpdate(index, value, record);
                }, 200);
              }}
            />
          </div>
        );
      }
    }
  ];

  const submitCreateForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const createNewAttribute = await createAttribute({
      name,
      description
    });
    if (
      attributes.length === 0 ||
      attributes.length < limit ||
      attributes[attributes.length - 1].serialNumber + 1 ===
        createNewAttribute.serialNumber
    ) {
      const updatedAttrbutes = attributes.slice();
      updatedAttrbutes.push(createNewAttribute);
      setAttributes(updatedAttrbutes.slice());
    }
    createForm.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    fetchAttributes({
      currentQuery: query,
      currentSkip: currentGlobalSkip
    });
  };

  const submitUpdatedForm = async (formValue) => {
    const { name, description } = formValue;
    setIsSavingData(true);
    const updateCreatedAttribute = await updateAttribute({
      name,
      description,
      attributeId: selectedId
    });
    const updatedAttributes = attributes.slice();
    updatedAttributes[currentSelectedIndex] = updateCreatedAttribute;
    setAttributes(updatedAttributes);
    createForm?.resetFields();
    setIsSavingData(false);
    setIsCreateModal(false);
    setIsUpdateModal(false);
  };

  return (
    <div className="distribution-content">
      {/* CREATE MODAL */}
      <Modal
        title={isUpdateModal ? 'UPDATE ATTRIBUTE' : 'NEW ATTRIBUTE'}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={isUpdateModal ? submitUpdatedForm : submitCreateForm}
          >
            <Form.Item label="Name" name="name">
              <Input id="attribute-inp" placeholder="Enter attribute name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea
                autoSize={{
                  minRows: 5,
                  maxRows: 5
                }}
                type="text"
                placeholder="Enter description"
              />
            </Form.Item>
            <Button loading={isSavingData} id="create-btn" htmlType="submit">
              {isUpdateModal ? '   UPDATE ATTRIBUTE' : '   ADD ATTRIBUTE'}
            </Button>
          </Form>
        </div>
      </Modal>
      <Header className="main-header">
        <Typography.Title className="header-title">ATTRIBUTES</Typography.Title>
        <Button onClick={() => setIsCreateModal(true)}>ADD ATTRIBUTE</Button>
      </Header>
      <div className="attributes-section">
        <div className="search-section">
          <div className="search">
            <SearchComponent
              name="Attributes"
              debounceTime={1}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchAttributes({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </div>
        </div>
        <div className="table-section  attributes-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              data={attributes}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchAttributes({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
