import client from '../apollo';
import { UPLOAD_DOCUMENT, UPLOAD_ICON, UPLOAD_PHOTO } from './graphql/mutation';
import { GET_FILE_SIGNED_URL } from './graphql/query';

export const postUploadIcon = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_ICON,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadIcon;
  } catch (error) {
    return '';
  }
};

export const uploadDistributorDocumentAsAdmin = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_DOCUMENT,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadDistributorDocumentAsAdmin;
  } catch (error) {
    return '';
  }
};

export const postUploadPhoto = async (fileName) => {
  try {
    const response = await client.mutate({
      mutation: UPLOAD_PHOTO,
      fetchPolicy: 'network-only',
      variables: {
        fileName
      }
    });
    return response?.data?.uploadPhoto;
  } catch (error) {
    return '';
  }
};

export const getFileSignedUrl = async (filePath) => {
  try {
    const response = await client.query({
      query: GET_FILE_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        filePath
      }
    });
    return response?.data?.getFileSignedUrl?.signedUrl;
  } catch (error) {
    return '';
  }
};
