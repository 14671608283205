/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { ProfileFilled } from '@ant-design/icons';
import { Avatar, Col, Divider, Empty, Form, Modal, Row, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as OrderEye } from '../../../../assets/other-icons/orderEye.svg';
import { ReactComponent as Dline1 } from '../../../../assets/timeline-icons/darkLine1.svg';
import { ReactComponent as DLine2 } from '../../../../assets/timeline-icons/darkLine2.svg';
import { ReactComponent as Done1 } from '../../../../assets/timeline-icons/done1.svg';
import { ReactComponent as Done2 } from '../../../../assets/timeline-icons/done2.svg';
import { ReactComponent as Done3 } from '../../../../assets/timeline-icons/done3.svg';
import { ReactComponent as Lline1 } from '../../../../assets/timeline-icons/lightLine1.svg';
import { ReactComponent as Lline2 } from '../../../../assets/timeline-icons/lightLine2.svg';
import { ReactComponent as Undone2 } from '../../../../assets/timeline-icons/undone2.svg';
import { ReactComponent as Undone3 } from '../../../../assets/timeline-icons/undone3.svg';
import CommonTable from '../../../../common/components/CommonTable';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT, ROUTES } from '../../../../common/constants';
import { getOrderById, getOrders } from '../../orders-repository';
import '../active-orders/active-order.less';

export function PastOrders({ query, selectedDate }) {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [activeOrders] = Form.useForm();
  const navigate = useNavigate();

  // STATES
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [orderID, setOrderID] = useState('');
  const [, setCurrentGlobalSkip] = useState(0);
  const [activeRequests, setActiveRequests] = useState([]);
  const [singleOrder, setSingleOrder] = useState([]);
  const [reqProducts, setReqProducts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paginationProp, setPaginationProp] = useState();

  const fetchActiveOrders = async ({ currentSkip }) => {
    setCurrentGlobalSkip(currentSkip);
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getOrders(
        {
          skip: currentSkip,
          limit: limit,
          query: query,
          dateFilter: selectedDate
        },
        'PAST'
      );

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveRequests(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchActiveOrders({
      currentSkip: 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedDate]);

  const openOrderModalData = async (ID) => {
    setLoadingData(true);
    const response = await getOrderById(ID);
    setSingleOrder(response.order);
    setReqProducts(response?.order?.orderSummary?.products.slice());
    console.log(response.order);
    activeOrders.setFieldValue('note', response?.order?.note);
    setIsCreateModal(true);
    setLoadingData(false);
  };

  const handleCancelCreate = () => {
    setIsCreateModal(false);
  };

  const openSelectedLink = (ID) => {
    const url = `${ROUTES.ORDER_INVOICE}?orderId=${ID}`;
    // eslint-disable-next-line no-undef
    navigate(url);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'serialNumber',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: '2'
    },
    {
      title: 'RECIPIENT NAME',
      dataIndex: 'serialNumber',
      key: '3',
      render: (value, record) => {
        return record?.distributor?.name;
      }
    },
    {
      title: 'NO. OF ITEMS',
      dataIndex: 'serialNumber',
      key: '4',
      render: (value, record) => {
        return record.items.length;
      }
    },
    {
      title: 'PLACED ON',
      dataIndex: 'createdAt',
      key: '5',
      render: (value) => {
        return (
          <div>
            {moment(value).format('MM/DD/YYYY')} | {moment(value).format('LT')}
          </div>
        );
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `$ ${value.toFixed(2)}`;
      }
    },
    {
      title: 'IS PAID',
      dataIndex: 'isPaid',
      key: '7',
      render: (value) => {
        return value ? (
          <Tag className="paid-tag">YES</Tag>
        ) : (
          <Tag className="pending-tag">PENDING</Tag>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'orderStatus',
      key: '8',
      render: () => {
        return <Tag className="paid-tag delivered-tag">DELIVERED</Tag>;
      }
    },
    {
      title: 'INVOICE',
      dataIndex: 'orderId',
      align: 'center',
      key: '9',
      render: (value, record) => {
        return (
          <ProfileFilled
            className="edit-icon "
            style={{ fontSize: '18px' }}
            onClick={() => openSelectedLink(record.id)}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'orderId',
      key: '9',
      render: (value, record) => {
        return (
          <OrderEye
            onClick={() => {
              setOrderID(value);
              openOrderModalData(record.id);
            }}
            className="edit-icon "
          />
        );
      }
    }
  ];

  const timeLine = () => {
    if (singleOrder.orderStatus === 'APPROVED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone2 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone3 />
          </section>
          <section>
            <Lline1 />
          </section>
        </>
      );
    }
    if (singleOrder.orderStatus === 'SHIPPED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done2 />
          </section>
          <section>
            <Lline2 />
          </section>
          <section>
            <Undone3 />
          </section>
          <section>
            <Lline1 />
          </section>
        </>
      );
    }
    if (singleOrder.orderStatus === 'DELIVERED') {
      return (
        <>
          <section>
            <Dline1 />
          </section>
          <section>
            <Done1 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done2 />
          </section>
          <section>
            <DLine2 />
          </section>
          <section>
            <Done3 />
          </section>
          <section>
            <Dline1 />
          </section>
        </>
      );
    }
  };

  return (
    <div className="active-order-content">
      {/* CREATE MODAL */}
      <Modal
        title={orderID}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="approve-modal active-modal"
        centered
      >
        <Divider />
        <div className="modal-content active-content">
          <Row>
            <Col>
              <div className="left">
                <div className="timeline">{timeLine()}</div>
                <div className="products-section orders-prod-list">
                  {reqProducts.length < 1 ? (
                    <Empty />
                  ) : (
                    reqProducts.map((prod) => {
                      return (
                        <div
                          className="product-card orders-products"
                          key={prod.purchasedVariant.variantId}
                        >
                          <div className="sect">
                            <div className="avatar-section">
                              <Avatar
                                src={prod.purchasedVariant.image}
                                size={60}
                                shape="square"
                              />
                            </div>
                            <div className="title-section">
                              {prod.title} - {prod.purchasedVariant.title} (
                              {prod.purchasedVariant.qty})
                            </div>
                          </div>
                          <div className="price-sect">
                            $ {prod.purchasedVariant.price.toFixed(2)}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <Divider />
                <div className="note-area">
                  <section className="note-title">NOTE</section>
                  <Form layout="vertical" form={activeOrders}>
                    <Form.Item name="note">
                      <TextArea
                        autoSize={{
                          minRows: 4,
                          maxRows: 4
                        }}
                        type="text"
                        disabled
                        placeholder="Enter note"
                      />
                    </Form.Item>
                  </Form>
                </div>
                <div className="shipping-area">
                  <section>SHIPPING CHARGES</section>
                  <section>${singleOrder?.shippingCharges?.toFixed(2)}</section>
                </div>
                <Divider />
                <div className="total-area">
                  <section>TOTAL</section>
                  <section>${singleOrder?.subTotal?.toFixed(2)}</section>
                </div>
              </div>
            </Col>
            <Col>
              <div className="right">
                <div className="customer-details">CUSTOMER DETAILS</div>
                <Divider dashed />
                <div className="customer-name">
                  <b> NAME:</b> {singleOrder?.distributor?.name}
                </div>
                <div className="customer-name">
                  <b> MOBILE NO :</b> {singleOrder?.distributor?.mobileNumber}
                </div>
                <Divider dashed />
                <div className="billing-details">
                  <b> BILLING ADDRESS:</b>
                  <span>
                    {singleOrder?.billingAddress?.addressLine1}&nbsp;
                    {singleOrder?.billingAddress?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.billingAddress?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.billingAddress?.zipCode}
                  </span>
                </div>
                <div className="billing-details">
                  <b> SHIPPING ADDRESS:</b>
                  <span>
                    {singleOrder?.address?.addressLine1}&nbsp;
                    {singleOrder?.address?.addressLine2}&nbsp;
                  </span>
                  <span>
                    {singleOrder?.address?.city}&nbsp;
                    {singleOrder?.address?.state}&nbsp;
                    {singleOrder?.address?.zipCode}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="table-section feature-prod-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowClassName={(record) =>
              record.isDisabled ? 'disabled-row' : null
            }
            columns={columns}
            data={activeRequests}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchActiveOrders({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
