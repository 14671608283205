import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation createCategory($data: CreateAttributeInput!) {
    createCategory(data: $data) {
      isCreated
      category {
        id
        name
        description
        isFeature
        isDisable
        serialNumber
        order
        subCategories {
          id
          name
          description
          isDisable
        }
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $name: String!
    $description: String!
    $categoryId: String!
  ) {
    updateCategory(
      data: { name: $name, description: $description }
      categoryId: $categoryId
    ) {
      isCreated
      category {
        id
        name
        description
        isFeature
        isDisable
        serialNumber
        order
        subCategories {
          id
          name
          description
          isDisable
        }
      }
    }
  }
`;

export const CREATE_SUB_CATEGORY = gql`
  mutation createSubCategory(
    $data: CreateAttributeInput!
    $categoryId: String!
  ) {
    createSubCategory(data: $data, categoryId: $categoryId) {
      isCreated
      subCategory {
        id
        name
        description
        isDisable
        products {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_SUB_CATEGORY = gql`
  mutation updateSubCategory(
    $name: String!
    $description: String!
    $subCategoryId: String!
  ) {
    updateSubCategory(
      data: { name: $name, description: $description }
      subCategoryId: $subCategoryId
    ) {
      isCreated
      subCategory {
        id
        name
        description
        isDisable
        products {
          id
          name
        }
      }
    }
  }
`;

export const BULK_VALUE_UPDATE = gql`
  mutation bulkValueUpdate($data: BulkValueUpdateInput!, $productId: String!) {
    bulkValueUpdate(data: $data, productId: $productId)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($data: UpdateProductInput!, $productId: String!) {
    updateProduct(data: $data, productId: $productId) {
      isCreated
      product {
        id
        name
        description
        image
        ingredientDocument
        attribute {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: UpdateProductInput!, $subCategoryId: String!) {
    createProduct(data: $data, subCategoryId: $subCategoryId) {
      isCreated
      product {
        id
        name
        image
        description
        isDisable
      }
    }
  }
`;

export const UPLOAD_VARIATION_IMAGES = gql`
  mutation uploadVariationImage($fileName: String!) {
    uploadVariationImage(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const CREATE_VARIANT = gql`
  mutation createVariation($data: VariationInput!, $productId: String!) {
    createVariation(data: $data, productId: $productId) {
      isCreated
      variation {
        id
        uid
        name
        searchableName
        distributorPrice
        retailerPrice
        jobberPrice
        images
        isDefault
      }
    }
  }
`;

export const UPDATE_VARIATION = gql`
  mutation updateVariation($data: VariationInput!, $variationId: String!) {
    updateVariation(data: $data, variationId: $variationId) {
      isCreated
      variation {
        id
        uid
        name
        searchableName
        distributorPrice
        retailerPrice
        isDefault
        jobberPrice
        price
        images
      }
    }
  }
`;

export const TOGGLE_DEFAULT = gql`
  mutation toggleDefaultVariation($variationId: String!) {
    toggleDefaultVariation(variationId: $variationId)
  }
`;

export const DELETE_VARIATION = gql`
  mutation deleteVariation($variationId: String!) {
    deleteVariation(variationId: $variationId)
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($categoryId: String!) {
    deleteCategory(categoryId: $categoryId)
  }
`;

export const DELETE_SUB_CATEGORY = gql`
  mutation deleteSubCategory($subCategoryId: String!) {
    deleteSubCategory(subCategoryId: $subCategoryId)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: String!) {
    deleteProduct(productId: $productId)
  }
`;

export const TOGGLE_FEATURE_CATEGORY = gql`
  mutation toggleFeatureCategory($categoryId: String!) {
    toggleFeatureCategory(categoryId: $categoryId)
  }
`;

export const ADD_RESTRICTED_STATES = gql`
  mutation addRestrictedStates($states: [String!]!, $subCategoryId: String!) {
    addRestrictedStates(states: $states, subCategoryId: $subCategoryId)
  }
`;

export const UPLOAD_PRODUCT_PHOTO = gql`
  mutation uploadProductImage($fileName: String!) {
    uploadProductImage(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const UPLOAD_PRODUCT_PDF = gql`
  mutation uploadProductPDF($fileName: String!) {
    uploadProductPDF(fileName: $fileName) {
      signedUrl
      getUrl
      fileName
    }
  }
`;

export const CREATE_BULK_VARIATIONS = gql`
  mutation createVariationInBulk(
    $variationsData: [VariationInput!]!
    $productId: String!
  ) {
    createVariationInBulk(
      variationsData: $variationsData
      productId: $productId
    ) {
      isCreated
      variations {
        id
        uid
        name
        searchableName
        distributorPrice
        retailerPrice
        jobberPrice
        price
        images
        ingredientDocument
      }
    }
  }
`;

export const TOGGLE_ENABLE_DISABLE_VARIATION = gql`
  mutation toggleDisableVariation($variationId: String!) {
    toggleDisableVariation(variationId: $variationId)
  }
`;

export const TOGGLE_ENABLE_DISABLE_PRODUCT = gql`
  mutation toggleDisableProduct($productId: String!) {
    toggleDisableProduct(productId: $productId)
  }
`;

export const UPDATE_VARIATION_FIRST_IMAGE = gql`
  mutation updateVariationFirstImage($variationId: String!, $imgUrl: String!) {
    updateVariationFirstImage(variationId: $variationId, imgUrl: $imgUrl) {
      isCreated
      variation {
        id
        uid
        name
        searchableName
        distributorPrice
        retailerPrice
        jobberPrice
        price
        images
        isDefault
        productId
        product {
          id
        }
        isDisable
      }
    }
  }
`;

export const UPDATE_BULK_VARIATIONS = gql`
  mutation updateVariationInBulk(
    $variationsData: [VariationInput!]!
    $productId: String!
  ) {
    updateVariationInBulk(
      variationsData: $variationsData
      productId: $productId
    ) {
      isCreated
      variations {
        id
        uid
        name
        searchableName
        distributorPrice
        retailerPrice
        jobberPrice
        price
        images
        isDefault
        productId
        product {
          id
        }
        isDisable
        ingredientDocument
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const TOGGLE_VISIBILITY_CATEGORY = gql`
  mutation toggleVisibilityCategory($categoryId: String!) {
    toggleVisibilityCategory(categoryId: $categoryId)
  }
`;

export const REARRANGE_SUB_CATEGORIES = gql`
  mutation rearrangeSubCategory($subCategoryIds: [String!]!) {
    rearrangeSubCategory(subCategoryIds: $subCategoryIds)
  }
`;

export const REARRANGE_PRODUCTS = gql`
  mutation rearrangeProduct($productIds: [String!]!) {
    rearrangeProduct(productIds: $productIds)
  }
`;
