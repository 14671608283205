import { gql } from '@apollo/client';

export const GET_ORDERS_REQUESTS = gql`
  query getOrders(
    $filters: FilterDataInput
    $orderType: OrderType!
    $dateFilter: FilterOnDateInput
  ) {
    getOrders(
      filters: $filters
      orderType: $orderType
      dateFilter: $dateFilter
    ) {
      orders {
        id
        orderId
        serialNumber
        isPlaced
        isPaid
        createdAt
        total
        isPaid
        subTotal
        orderStatus
        items {
          qty
        }
        distributor {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query getOrderById($orderId: String!) {
    getOrderById(orderId: $orderId) {
      isOrderExist
      order {
        id
        orderId
        note
        shippingCharges
        total
        subTotal
        orderStatus
        isPaid
        billingAddress {
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
        }
        address {
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
        }
        distributor {
          id
          name
          mobileNumber
        }
        orderSummary {
          products {
            productId
            title
            purchasedVariant {
              variantId
              uid
              title
              price
              qty
              image
            }
          }
          total
          subTotal
        }
      }
    }
  }
`;

export const GET_PENDING_COUNT = gql`
  query getOrders($filters: FilterDataInput, $orderType: OrderType!) {
    getOrders(filters: $filters, orderType: $orderType) {
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
