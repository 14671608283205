import { Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PendingRequests } from './components/PendingRequests/PendingRequests';
import { RejectedRequests } from './components/RejectedRequests/RejectedRequests';

import './requests.less';

import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';

export function Requests() {
  const navigate = useNavigate();
  return (
    <div className="distribution-content">
      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate('/')} />
          <span className="request-header-title">DISTRIBUTORS/</span>REQUESTS
        </Typography.Title>
      </Header>
      <div className="requests-section">
        <div className="request-tabs">
          <Tabs
            items={[
              {
                key: '1',
                label: `PENDING `,
                children: <PendingRequests />
              },
              {
                key: '2',
                label: `REJECTED `,
                children: <RejectedRequests />
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
}
