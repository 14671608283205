import Sider from 'antd/es/layout/Sider';
import Layout, { Content } from 'antd/es/layout/layout';
import React from 'react';
import './App.less';
import ContentRoutes from './ContentRoutes';
import { ManagerSIdeBar } from './components/ManagerSideBar';
import { SideBar } from './components/SideBar';

function App({ user }) {
  const breakpoint = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px'
  };

  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  return (
    <div className="App">
      <Layout>
        <Sider breakpoint={breakpoint} theme="light" width={224}>
          {isManager ? <ManagerSIdeBar user={user} /> : <SideBar user={user} />}
        </Sider>
        <Content>
          <ContentRoutes user={user} />
        </Content>
      </Layout>
    </div>
  );
}

export default App;
