/* eslint-disable no-undef */
/* eslint-disable no-console */
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  QRCode,
  Select,
  Spin,
  Tag,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import Dragger from 'antd/es/upload/Dragger';
import axios from 'axios';
import { compact, isEmpty } from 'lodash';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import CommonTable from '../../../../common/components/CommonTable';
import './single-product.less';

import { ReactComponent as UploadIcon } from '../../../../assets/other-icons/Upload.svg';
import { ReactComponent as BackBtn } from '../../../../assets/other-icons/backBtn.svg';
import { ReactComponent as DeleteBtn } from '../../../../assets/other-icons/deletebtn.svg';
import { ReactComponent as EditIcon } from '../../../../assets/other-icons/edit.svg';
import { ReactComponent as QRBtnDefault } from '../../../../assets/other-icons/qr_code_default.svg';
import { ReactComponent as QRBtnUploaded } from '../../../../assets/other-icons/qr_code_uploaded.svg';
import { getFileSignedUrl } from '../../../../common/common-repository';
import LoaderComponent from '../../../../common/components/LoaderComponent';
import configEnv from '../../../../common/config';
import { formValidatorRules } from '../../../../common/utils';
import {
  bulkValueUpdate,
  checkIfProductUIDExist,
  createVariation,
  createVariationInBulk,
  deleteVariation,
  getAllAttributes,
  getProductById,
  toggleDefaultVariation,
  toggleDisableVariation,
  updateProduct,
  updateVariation,
  updateVariationFirstImage,
  updateVariationInBulk,
  uploadProductImage,
  uploadProductPDF,
  uploadVariationImage
} from '../../categories-repository';

export function SingleProduct({ user }) {
  let isManager = false;
  if (user && user.type === 'MANAGER') {
    isManager = true;
  }

  const { product_Id: prodId } = useParams();
  const navigate = useNavigate();
  const { required } = formValidatorRules;
  const [productForm] = Form.useForm();
  const [bulkValueForm] = Form.useForm();
  const [variantForm] = Form.useForm();

  const startingStateForDoc = {
    isUploading: false,
    url: '',
    type: 'UNKNOWN',
    title: ''
  };

  // STATES
  const [singleProduct, setSingleProduct] = useState([]);
  const [, setIsLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [variations, setVariations] = useState([]);
  const [isSavingData, setIsSavingData] = useState(false);
  const [defaultAttribute, setDefaultAttribute] = useState('');
  const [isSavingBulk, setIsSavingBulk] = useState(false);
  const [isBulkValueModal, setIsBulkValueModal] = useState(false);
  const [isQRModal, setIsQRModal] = useState(false);
  const [isSavingVariantion, setIsSavingVariantion] = useState(false);
  const [isAddVariantModal, setIsAddVariantModal] = useState(false);
  const [isVariationUpdate, setIsVariationUpdate] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [imgs, setImgs] = useState([]);
  const [isUIDExist, setIsUIDExist] = useState();
  const [uploadedImage, setUploadedImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadedPdf, setUploadedPdf] = useState('');
  const [uploadingPdf, setUploadingPdf] = useState(false);

  // IMAGE STATES
  const [varImg1, setVarImg1] = useState(startingStateForDoc);
  const [varImg2, setVarImg2] = useState(startingStateForDoc);
  const [varImg3, setVarImg3] = useState(startingStateForDoc);
  const [varImg4, setVarImg4] = useState(startingStateForDoc);
  const [varImg5, setVarImg5] = useState(startingStateForDoc);
  const [varImg6, setVarImg6] = useState(startingStateForDoc);

  const onLoad = async () => {
    getProductById(prodId).then((fetchedProduct) => {
      setSingleProduct(fetchedProduct?.product);
      setVariations(fetchedProduct.product.variations.slice());
      setDefaultAttribute(fetchedProduct?.product?.attribute?.id);
      setUploadedImage(fetchedProduct?.product?.image);
      productForm?.setFieldsValue({
        name: fetchedProduct.product.name,
        description: fetchedProduct?.product?.description
      });
      setIsLoading(false);
      setLoadingData(false);
    });
  };
  function doDownload(url, fileName) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const downloadCanvasQRCode = () => {
    console.log('here');
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      doDownload(url, 'QRCode.png');
    }
  };

  const getAttributesList = async () => {
    const response = await getAllAttributes();
    setAttributes(response?.attributes.slice());
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
    getAttributesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    setFilterValue(value);
  };

  const handleCancelBulkValue = () => {
    bulkValueForm?.resetFields();
    setIsBulkValueModal(false);
  };

  const handleQRValue = () => {
    setIsQRModal(false);
    setUploadingPdf(false);
    setUploadedPdf('');
    setSelectedVariation(null);
  };

  const handleCancelVariantModal = () => {
    setIsUIDExist();
    variantForm.resetFields();
    setVarImg1(startingStateForDoc);
    setVarImg2(startingStateForDoc);
    setVarImg3(startingStateForDoc);
    setVarImg4(startingStateForDoc);
    setVarImg5(startingStateForDoc);
    setVarImg6(startingStateForDoc);
    setIsVariationUpdate(false);
    setImgs([]);
    setIsAddVariantModal(false);
  };

  const makeVariationDefault = async (ID) => {
    await toggleDefaultVariation(ID);
    onLoad();
  };

  const deleteSelectedVariation = async (record) => {
    if (record.isDefault) {
      message.error('You cannot delete default product');
      return;
    }
    await deleteVariation(record.id);
    onLoad();
  };

  const variationUpdate = (index, value, record) => {
    setIsLoading(true);
    setIsVariationUpdate(true);
    setTimeout(() => {
      setIsAddVariantModal(true);
      setIsLoading(false);
      variantForm.setFieldsValue({
        pID: record.uid,
        name: record.name,
        distPrice: record.distributorPrice,
        searchableName: record.searchableName,
        retailPrice: record.retailerPrice,
        jobberPrice: record.jobberPrice
      });
      const { images = [] } = record;
      setImgs(images);
    }, 500);
  };

  const openQRForVariant = (variant) => {
    setSelectedVariation(variant);
    setIsQRModal(true);
    setUploadedPdf(variant?.ingredientDocument);
  };

  const toggleEnableDisable = async (index, record) => {
    if (record.isDefault) {
      message.error("DEFAULT VARIATION CAN'T BE DISABLED");
      return;
    }
    variations[index] = {
      ...variations[index],
      isDisable: !(variations[index].isDisable || false)
    };
    await toggleDisableVariation(variations[index].id);
    setVariations(variations.slice());
  };

  const beforeUpload = (file) => {
    const isLT1MB = file.size / 1024 / 1024 <= 1;
    if (!isLT1MB) {
      message.error(`File must smaller than 1MB!`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  const onImageDrag = async (doc, record) => {
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Updating image'
    });
    const fileName = doc.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    const response = await uploadProductImage(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: doc.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    await updateVariationFirstImage(record.id, url);
    onLoad();
    message.open({
      key,
      type: 'success',
      content: 'Image updated'
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'name',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'DISTRIBUTOR PRICE ',
      dataIndex: 'distributorPrice',
      key: '4',
      render: (value) => {
        return `$ ${value}`;
      }
    },
    {
      title: 'RETAILER PRICE ',
      dataIndex: 'retailerPrice',
      key: '5',
      render: (value) => {
        return `$ ${value}`;
      }
    },
    {
      title: 'JOBBER PRICE ',
      dataIndex: 'jobberPrice',
      key: '6',
      render: (value) => {
        return `$ ${value}`;
      }
    },
    {
      title: 'IMAGES ',
      dataIndex: 'images',
      key: '7',
      render: (value) => {
        // eslint-disable-next-line no-unreachable-loop
        const imgUrls = [];
        const limitLength = value.length <= 2 ? value.length : 3;
        for (let i = 0; i < limitLength; i += 1) {
          if (i === 2) {
            imgUrls.push(<Tag>{`+ ${value.length - 2}`}</Tag>);
          } else {
            imgUrls.push(<Avatar src={value[i]} shape="square" />);
          }
        }
        return <div className="var-images">{imgUrls}</div>;
      }
    },
    !isManager
      ? {
          title: ' ',
          dataIndex: 'name',
          key: '8',
          render: (value, record) => {
            return (
              <Dragger
                showUploadList={false}
                accept="image/*"
                beforeUpload={beforeUpload}
                multiple={false}
                onChange={(doc) => {
                  onImageDrag(doc, record);
                }}
              >
                DRAG IMAGE HERE
              </Dragger>
            );
          }
        }
      : {},
    {
      title: '',
      dataIndex: 'name',
      key: '9',
      render: (value, record, index) => {
        if (isManager) {
          if (record.isDefault) {
            return (
              <div className="btns-section">
                <Button className="default-btn">DEFAULT</Button>
              </div>
            );
          }
          return (
            <div className="btns-section">
              <Popconfirm
                title="MAKE DEFAULT"
                description="ARE YOU SURE YOU WANT TO MAKE IT DEFAULT VARIATION"
                onConfirm={() => makeVariationDefault(record.id)}
                okText="YES"
                cancelText="NO"
              >
                <Button className="non-default-btn">MAKE DEFAULT</Button>
              </Popconfirm>
            </div>
          );
        }
        if (record.isDefault) {
          return (
            <div className="btns-section">
              <Button className="default-btn">DEFAULT</Button>
              <EditIcon
                className="edit-icon"
                onClick={() => {
                  variantForm?.resetFields();
                  setCurrentSelectedIndex(index);
                  setIsVariationUpdate(true);
                  setSelectedId(record.id);
                  setTimeout(() => {
                    variationUpdate(index, value, record);
                  }, 200);
                }}
              />
              <Popconfirm
                title="DELETE VARIATION"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                      width: '10px'
                    }}
                  />
                }
                description="ARE YOU SURE YOU WANT TO DELETE THIS VARIATION"
                onConfirm={() => deleteSelectedVariation(record)}
                okText="YES"
                cancelText="NO"
              >
                <DeleteBtn className="edit-icon" />
              </Popconfirm>
              {record.ingredientDocument ? (
                <QRBtnUploaded
                  className="edit-icon"
                  onClick={() => {
                    openQRForVariant(record);
                  }}
                />
              ) : (
                <QRBtnDefault
                  className="edit-icon"
                  onClick={() => {
                    openQRForVariant(record);
                  }}
                />
              )}
            </div>
          );
        }
        return (
          <div className="btns-section">
            <Popconfirm
              title="MAKE DEFAULT"
              description="ARE YOU SURE YOU WANT TO MAKE IT DEFAULT VARIATION"
              onConfirm={() => makeVariationDefault(record.id)}
              okText="YES"
              cancelText="NO"
            >
              <Button className="non-default-btn">MAKE DEFAULT</Button>
            </Popconfirm>
            <EditIcon
              className="edit-icon"
              onClick={() => {
                variantForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsVariationUpdate(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  variationUpdate(index, value, record);
                }, 200);
              }}
            />
            <Popconfirm
              title="DELETE VARIATION"
              description="ARE YOU SURE YOU WANT TO DELETE THIS VARIATION"
              onConfirm={() => deleteSelectedVariation(record)}
              okText="YES"
              cancelText="NO"
            >
              <DeleteBtn className="edit-icon" />
            </Popconfirm>
            {record.ingredientDocument ? (
              <QRBtnUploaded
                className="edit-icon"
                onClick={() => {
                  openQRForVariant(record);
                }}
              />
            ) : (
              <QRBtnDefault
                className="edit-icon"
                onClick={() => {
                  openQRForVariant(record);
                }}
              />
            )}
          </div>
        );
      }
    },
    !isManager
      ? {
          title: 'ACTION ',
          dataIndex: 'id',
          key: '10',
          render: (value, record, index) => {
            if (record.isDisable) {
              return (
                <Button
                  onClick={() => toggleEnableDisable(index, record)}
                  type="text"
                >
                  DISABLED
                </Button>
              );
            }
            return (
              <Popconfirm
                title="DISABLE VARIATION"
                description="ARE YOU SURE YOU WANT TO DISABLE THIS VARIATION"
                onConfirm={() => toggleEnableDisable(index, record)}
                okText="YES"
                cancelText="NO"
              >
                <Button type="text">ENABLED</Button>
              </Popconfirm>
            );
          }
        }
      : {}
  ];

  const beforeUploadProd = (file) => {
    const isLT1MB = file.size / 1024 / 1024 <= 1;
    if (!isLT1MB) {
      message.error(`File must smaller than 1MB!`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  const beforeUploadProdPDF = (file) => {
    const isLT50MB = file.size / 1024 / 1024 <= 50;
    if (!isLT50MB) {
      message.error(`File must smaller than 50MB!`);
      return !isLT50MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  const onIconUploadProd = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await uploadProductImage(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedImage(url);
    setUploadingImage(false);
  };

  const onIconUploadProdPDF = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType !== 'application/pdf') {
      message.error(`File must be PDF`);
      return;
    }
    setUploadingPdf(true);
    const response = await uploadProductPDF(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setUploadedPdf(url);
    const data = {
      name: selectedVariation.name,
      uid: selectedVariation.uid,
      searchableName: selectedVariation.searchableName,
      distributorPrice: selectedVariation.distributorPrice,
      retailerPrice: selectedVariation.retailerPrice,
      jobberPrice: selectedVariation.jobberPrice,
      images: selectedVariation.images,
      ingredientDocument: url
    };
    await updateVariation(data, selectedVariation.id);
    onLoad();
    setUploadingPdf(false);
  };

  const onSubmitBulkValue = async (formValue) => {
    const { distPrice, retailPrice, jobberPrice } = formValue;
    const data = {
      distributorPrice: distPrice,
      retailerPrice: retailPrice,
      jobberPrice: jobberPrice
    };
    setIsSavingBulk(true);
    const updateVariations = await bulkValueUpdate(data, prodId);
    const newVariations = variations.slice();
    newVariations[prodId] = updateVariations;
    setVariations(newVariations);
    setIsSavingBulk(false);
    bulkValueForm?.resetFields();
    setIsBulkValueModal(false);
    onLoad();
  };

  const listOptions = [];
  for (let i = 0; i < attributes.length; i += 1) {
    listOptions.push({
      label: attributes[i].name,
      value: attributes[i].id
    });
  }

  const onSubmitVariantionForm = async (formValue) => {
    const { pID, name, distPrice, retailPrice, jobberPrice, searchableName } =
      formValue;
    const imageData = [
      varImg1.url ? varImg1.url : null,
      varImg2.url ? varImg2.url : null,
      varImg3.url ? varImg3.url : null,
      varImg4.url ? varImg4.url : null,
      varImg5.url ? varImg5.url : null,
      varImg6.url ? varImg6.url : null
    ];

    const newImageData = [];
    for (let i = 0; i < imageData.length; i += 1) {
      if (imageData[i] !== null) {
        newImageData.push(imageData[i]);
      }
    }
    if (newImageData.length === 0) {
      message.error('Atleast 1 image needed');
      return;
    }
    if (isUIDExist) {
      message.error('UID already exist');
      return;
    }
    const data = {
      name,
      searchableName,
      uid: pID,
      distributorPrice: parseFloat(distPrice),
      retailerPrice: parseFloat(retailPrice),
      jobberPrice: parseFloat(jobberPrice),
      images: newImageData
    };
    setIsSavingVariantion(true);
    const newVariation = await createVariation(data, prodId);
    const updatedVariations = variations.slice();
    updatedVariations.push(newVariation);
    setVariations(updatedVariations.slice());
    variantForm.resetFields();
    setVarImg1(startingStateForDoc);
    setVarImg2(startingStateForDoc);
    setVarImg3(startingStateForDoc);
    setVarImg4(startingStateForDoc);
    setVarImg5(startingStateForDoc);
    setVarImg6(startingStateForDoc);
    setIsSavingVariantion(false);
    setIsAddVariantModal(false);
  };

  const updateVariationForm = async (formValue) => {
    const { pID, name, distPrice, retailPrice, jobberPrice, searchableName } =
      formValue;

    const imageData = [
      varImg1.url ? varImg1.url : imgs[0],
      varImg2.url ? varImg2.url : imgs[1],
      varImg3.url ? varImg3.url : imgs[2],
      varImg4.url ? varImg4.url : imgs[3],
      varImg5.url ? varImg5.url : imgs[4],
      varImg6.url ? varImg6.url : imgs[5]
    ];

    const newImageData = [];
    for (let i = 0; i < imageData.length; i += 1) {
      if (imageData[i] !== null && imageData[i]) {
        newImageData.push(imageData[i]);
      }
    }

    if (newImageData.length === 0) {
      message.error('Atleast 1 image needed');
      return;
    }

    if (newImageData.length <= 0) {
      newImageData.push(...imgs);
    }

    const data = {
      name,
      uid: pID,
      searchableName: searchableName,
      distributorPrice: parseFloat(distPrice),
      retailerPrice: parseFloat(retailPrice),
      jobberPrice: parseFloat(jobberPrice),
      images: newImageData
    };
    setIsSavingVariantion(true);
    const newVariation = await updateVariation(data, selectedId);
    const updatedVariations = variations.slice();
    updatedVariations[currentSelectedIndex] = newVariation;
    setVariations(updatedVariations.slice());
    variantForm.resetFields();
    setVarImg1(startingStateForDoc);
    setVarImg2(startingStateForDoc);
    setVarImg3(startingStateForDoc);
    setVarImg4(startingStateForDoc);
    setVarImg5(startingStateForDoc);
    setVarImg6(startingStateForDoc);
    setImgs([]);
    setIsVariationUpdate(false);
    setIsSavingVariantion(false);
    setIsAddVariantModal(false);
  };

  const onIconUpload = async (selectedFile, docType) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }

    if (docType === 0) {
      setVarImg1({
        url: varImg1.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'image1'
      });
    } else if (docType === 1) {
      setVarImg2({
        url: varImg2.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'image2'
      });
    } else if (docType === 2) {
      setVarImg3({
        url: varImg3.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'image3'
      });
    } else if (docType === 3) {
      setVarImg4({
        url: varImg4.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'image4'
      });
    } else if (docType === 4) {
      setVarImg5({
        url: varImg5.url,
        isUploading: true,
        type: 'UNKNOWN',
        title: 'image5'
      });
    } else {
      setVarImg6({
        url: varImg6.url,
        type: 'UNKNOWN',
        isUploading: true,
        title: 'image6'
      });
    }
    const response = await uploadVariationImage(fileName);
    const { signedUrl, getUrl } = response;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);

    if (docType === 0) {
      setVarImg1({
        url: url,
        isUploading: false,
        type: fileType,
        title: 'image1'
      });
    } else if (docType === 1) {
      setVarImg2({
        url: url,
        isUploading: false,
        type: fileType,
        title: 'image2'
      });
    } else if (docType === 2) {
      setVarImg3({
        url: url,
        isUploading: false,
        type: fileType,
        title: 'image3'
      });
    } else if (docType === 3) {
      setVarImg4({
        url: url,
        isUploading: false,
        type: fileType,
        title: 'image4'
      });
    } else if (docType === 4) {
      setVarImg5({
        url: url,
        isUploading: false,
        type: fileType,
        title: 'image5'
      });
    } else {
      setVarImg6({
        url: url,
        type: fileType,
        isUploading: false,
        title: 'image6'
      });
    }
  };

  const uidAlreadyExist = async (e) => {
    const UID = e.target.value;

    await checkIfProductUIDExist(UID).then((value) => {
      setIsUIDExist(value);
    });
  };

  const saveProductForm = async (formValue) => {
    const { name, description } = formValue;
    const data = {
      name: name || singleProduct?.name,
      description: description || singleProduct?.description,
      attribute: filterValue || attributes[0]?.id,
      image: uploadedImage
    };

    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Updating information'
    });
    setIsSavingData(true);
    const updatedProduct = await updateProduct(data, prodId);
    const { product } = updatedProduct;
    setSingleProduct(product);
    message.open({
      key,
      type: 'success',
      content: 'Information updated'
    });
    setIsSavingData(false);
    //
  };

  const removeImg = (doc) => {
    const newArr = imgs.slice();

    if (doc === 0) {
      // eslint-disable-next-line no-unused-expressions
      setVarImg1({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image1'
      });

      if (imgs) {
        newArr.splice(0, 1);
        setImgs(newArr);
      }
    }
    if (doc === 1) {
      setVarImg2({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image2'
      });
      if (imgs) {
        newArr.splice(1, 1);
        setImgs(newArr);
      }
    }
    if (doc === 2) {
      setVarImg3({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image3'
      });
      if (imgs) {
        newArr.splice(2, 1);
        setImgs(newArr);
      }
    }
    if (doc === 3) {
      setVarImg4({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image4'
      });
      if (imgs) {
        newArr.splice(3, 1);
        setImgs(newArr);
      }
    }
    if (doc === 4) {
      setVarImg5({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image5'
      });
      if (imgs) {
        newArr.splice(4, 1);
        setImgs(newArr);
      }
    }
    if (doc === 5) {
      setVarImg6({
        url: undefined,
        isUploading: false,
        type: 'UNKNOWN',
        title: 'image6'
      });
      if (imgs) {
        newArr.splice(5, 1);
        setImgs(newArr);
      }
    }
  };

  const excelBeforeUpload = (file) => {
    const fileType = file.type;
    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const isExcelFile = allowedTypes.includes(fileType);
    if (!isExcelFile) {
      message.error('You can only upload Excel files!');
      return !isExcelFile;
    }
    return false;
  };

  const handleFileUpload = async (file) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    let bulkData;
    const key = 'updatable';
    reader.onload = async (event) => {
      message.open({
        key,
        type: 'loading',
        content: 'Adding Variants'
      });
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const outputData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const jsonData = compact(outputData.filter((obj) => !isEmpty(obj)));

      const mappedArray = jsonData.map(
        ([
          UID,
          Name,
          SearchableName,
          DistributorPrice,
          RetailerPrice,
          JobberPrice
        ]) => ({
          UID,
          Name,
          SearchableName,
          DistributorPrice,
          RetailerPrice,
          JobberPrice
        })
      );
      mappedArray.shift();
      const newData = [];

      for (let i = 0; i < mappedArray.length; i += 1) {
        newData.push({
          name: mappedArray[i].Name,
          searchableName: mappedArray[i].SearchableName,
          uid: `${mappedArray[i].UID}`,
          distributorPrice: parseFloat(
            mappedArray[i].DistributorPrice.toFixed(2),
            10
          ),
          retailerPrice: parseFloat(
            mappedArray[i].RetailerPrice.toFixed(2),
            10
          ),
          jobberPrice: parseFloat(mappedArray[i].JobberPrice.toFixed(2), 10),
          images: []
        });
      }
      bulkData = newData;

      const response = await createVariationInBulk(bulkData, prodId);
      onLoad();
      if (response)
        message.open({
          key,
          type: 'success',
          content: 'Added successfully'
        });
    };
    reader.readAsArrayBuffer(file);
  };

  const toUpdateArray = [];
  for (let i = 0; i < variations.length; i += 1) {
    toUpdateArray.push({
      uid: variations[i].uid,
      name: variations[i].name,
      distributorPrice: variations[i].distributorPrice,
      retailerPrice: variations[i].retailerPrice,
      jobberPrice: variations[i].jobberPrice,
      searchableName: variations[i].searchableName
    });
  }

  const downloadAsExcel = (dataArray) => {
    const worksheet = XLSX.utils.json_to_sheet(dataArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateBulkVariations = async (file) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    let bulkData;
    const key = 'updatable';
    reader.onload = async (event) => {
      message.open({
        key,
        type: 'loading',
        content: 'Updating Variants',
        duration: 0
      });
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const outputData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const jsonData = compact(outputData.filter((obj) => !isEmpty(obj)));

      const mappedArray = jsonData.map(
        ([
          uid,
          name,
          distributorPrice,
          retailerPrice,
          jobberPrice,
          searchableName
        ]) => ({
          uid,
          name,
          distributorPrice,
          retailerPrice,
          jobberPrice,
          searchableName
        })
      );
      mappedArray.shift();
      const newData = [];

      for (let i = 0; i < mappedArray.length; i += 1) {
        newData.push({
          name: mappedArray[i].name,
          uid: `${mappedArray[i].uid}`,
          distributorPrice: parseFloat(
            mappedArray[i].distributorPrice.toFixed(2),
            10
          ),
          retailerPrice: parseFloat(
            mappedArray[i].retailerPrice.toFixed(2),
            10
          ),
          jobberPrice: parseFloat(mappedArray[i].jobberPrice.toFixed(2)),
          searchableName: mappedArray[i].searchableName
          // images: []
        });
      }
      bulkData = newData;
      const response = await updateVariationInBulk(bulkData, prodId);
      if (response)
        message.open({
          key,
          type: 'success',
          content: 'Updated successfully'
        });
      onLoad();
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="distribution-content">
      {/* QR Modal */}
      <Modal
        title="QR Code"
        open={isQRModal}
        onCancel={handleQRValue}
        footer={null}
        className="qr-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <div className="qr-code-container">
            {selectedVariation && uploadedPdf ? (
              <div className="pdf-container">
                <div id="myqrcode">
                  <QRCode
                    value={`${configEnv.SERVER_URL}api/ingredients/variants/${selectedVariation.uid}`}
                    bgColor="#fff"
                    size={250}
                  />
                </div>
                <div style={{ height: '10px' }} />
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    borderRadius: '4px',
                    width: '80%'
                  }}
                  type="primary"
                  onClick={downloadCanvasQRCode}
                >
                  DOWNLOAD <AiOutlineDownload size={16} />
                </Button>
              </div>
            ) : (
              <div className="pdf-container with-border">PDF Not Added</div>
            )}
          </div>
          <div className="image-select">
            <div className="prod-image-ipload">
              <Upload
                disabled={isManager}
                accept=".pdf"
                onChange={onIconUploadProdPDF}
                beforeUpload={beforeUploadProdPDF}
                showUploadList={false}
              >
                {uploadedPdf && !uploadingPdf && (
                  <div style={{ textAlign: 'center' }}>
                    <p className="" style={{ padding: 0, margin: 0 }}>
                      REPLACE PDF
                    </p>
                    <a
                      href={uploadedPdf}
                      onClick={(e) => e.stopPropagation()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View File
                    </a>
                  </div>
                )}
                {!uploadedPdf && !uploadingPdf && (
                  <p className="upload-banner-text">UPLOAD PDF</p>
                )}
                {uploadingPdf && <LoadingOutlined />}
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
      {/* BULK VALUE MODAL */}
      <Modal
        title="BULK VALUE UPDATE"
        open={isBulkValueModal}
        onCancel={handleCancelBulkValue}
        footer={null}
        className="approve-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={bulkValueForm}
            onFinish={onSubmitBulkValue}
          >
            <Form.Item
              label="Distributor Price"
              name="distPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <Form.Item
              label="Retailer Price"
              name="retailPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <Form.Item
              label="Jobber Price"
              name="jobberPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <Button loading={isSavingBulk} id="create-btn" htmlType="submit">
              SAVE
            </Button>
          </Form>
        </div>
      </Modal>
      {/* ADD VARIANT MODAL  */}
      <Modal
        title={isVariationUpdate ? 'UPDATE VARIATION' : ' NEW VARIATION'}
        open={isAddVariantModal}
        onCancel={handleCancelVariantModal}
        footer={null}
        className="approve-modal variation-add-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={variantForm}
            onFinish={
              isVariationUpdate ? updateVariationForm : onSubmitVariantionForm
            }
          >
            <Form.Item label="Unique Product ID" name="pID" rules={[required]}>
              <Input
                id="product-input"
                placeholder="Enter UUID"
                onChange={uidAlreadyExist}
                disabled={isVariationUpdate}
              />
            </Form.Item>
            {isUIDExist === true ? (
              <span className="error-warning">UID already exist</span>
            ) : null}
            <Form.Item label="Name" name="name" rules={[required]}>
              <Input id="product-input" placeholder="Enter variant name" />
            </Form.Item>
            <Form.Item
              label="Searchable name"
              name="searchableName"
              rules={[required]}
            >
              <Input id="product-input" placeholder="Enter searchable name" />
            </Form.Item>
            <Form.Item
              label="Distributor Price"
              name="distPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <Form.Item
              label="Retailer Price"
              name="retailPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <Form.Item
              label="Jobber Price"
              name="jobberPrice"
              rules={[required]}
            >
              <InputNumber
                prefix="$"
                id="bulk-inp"
                placeholder="Enter category name"
                controls={false}
              />
            </Form.Item>
            <div className="upload-images">
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 0);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg1?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg1.url || imgs[0] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg1.url || imgs[0] ? (
                  <span onClick={() => removeImg(0)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 1);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg2?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg2.url || imgs[1] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg2.url || imgs[1] ? (
                  <span onClick={() => removeImg(1)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 2);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg3?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg3.url || imgs[2] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg3.url || imgs[2] ? (
                  <span onClick={() => removeImg(2)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 3);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg4?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg4.url || imgs[3] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg4.url || imgs[3] ? (
                  <span onClick={() => removeImg(3)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 4);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg5?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg5.url || imgs[4] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg5.url || imgs[4] ? (
                  <span onClick={() => removeImg(4)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
              <div className="img-sect">
                <Upload
                  onChange={(doc) => {
                    onIconUpload(doc, 5);
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  {varImg6?.isUploading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={varImg6.url || imgs[5] || <UploadIcon />}
                      size={59}
                      shape="square"
                    />
                  )}
                </Upload>
                {varImg6.url || imgs[5] ? (
                  <span onClick={() => removeImg(5)} className="remove-btn">
                    REMOVE
                  </span>
                ) : null}
              </div>
            </div>
            <Button
              loading={isSavingVariantion}
              id="create-btn"
              htmlType="submit"
            >
              {isVariationUpdate ? 'UPDATE' : ' SAVE'}
            </Button>
          </Form>
        </div>
      </Modal>

      <Header className="main-header">
        <Typography.Title className="header-title request-header">
          <BackBtn className="back-btn" onClick={() => navigate(-1)} />
          {singleProduct?.name || ' NEW PRODUCT'}
        </Typography.Title>
      </Header>
      <div className="categories-section new-product-section">
        {loadingData ? (
          <LoaderComponent />
        ) : (
          <div className="prod-info">
            <Form
              layout="vertical"
              form={productForm}
              onFinish={saveProductForm}
            >
              <div className="info-prod-section">
                <div className="name-prod">
                  <Form.Item
                    id="prod-name"
                    name="name"
                    label="Name"
                    rules={[required]}
                  >
                    <Input disabled={isManager} />
                  </Form.Item>
                </div>
                <div className="desc-prod">
                  <Form.Item
                    name="description"
                    id="prod-desc"
                    label="Description"
                    rules={[required]}
                  >
                    <Input disabled={isManager} />
                  </Form.Item>
                </div>
              </div>
              <div className="attr-btn-section">
                <div className="align-atr-image">
                  <div className="attribute-selection">
                    <span className="attr-dummy">ATTRIBUTES</span>
                    <span>
                      <Select
                        disabled={isManager}
                        placeholder="Attribute"
                        defaultValue={defaultAttribute || attributes[0].id}
                        style={{
                          width: 120
                        }}
                        onChange={handleChange}
                        options={listOptions}
                      />
                    </span>
                  </div>
                  <div className="image-select">
                    <div className="prod-image-ipload">
                      <Upload
                        disabled={isManager}
                        accept="image/*"
                        onChange={onIconUploadProd}
                        beforeUpload={beforeUploadProd}
                        showUploadList={false}
                      >
                        {uploadedImage && !uploadingImage && (
                          <img
                            src={uploadedImage}
                            className="sample-img "
                            alt="xyz"
                          />
                        )}
                        {!uploadedImage && !uploadingImage && (
                          <p className="upload-banner-text">
                            UPDATE PRODUCT IMAGE
                          </p>
                        )}
                        {uploadingImage && <LoadingOutlined />}
                      </Upload>
                    </div>
                  </div>
                  {/* <div className="image-select">
                    <div className="prod-image-ipload">
                      <Upload
                        disabled={isManager}
                        accept=".pdf"
                        onChange={onIconUploadProdPDF}
                        beforeUpload={beforeUploadProdPDF}
                        showUploadList={false}
                      >
                        {uploadedPdf && !uploadingPdf && (
                          <div style={{ textAlign: 'center' }}>
                            <p className="" style={{ padding: 0, margin: 0 }}>
                              REPLACE PDF
                            </p>
                            <a
                              href={uploadedPdf}
                              onClick={(e) => e.stopPropagation()}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View File
                            </a>
                          </div>
                        )}
                        {!uploadedPdf && !uploadingPdf && (
                          <p className="upload-banner-text">UPLOAD PDF</p>
                        )}
                        {uploadingPdf && <LoadingOutlined />}
                      </Upload>
                    </div>
                  </div> */}
                </div>
                <div>
                  <Button
                    loading={isSavingData}
                    htmlType="submit"
                    className="save-btn-product"
                    disabled={isManager}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </Form>
            <Divider dashed />
            <div className="variation-section">
              <div className="top-section">
                {!isManager ? (
                  <Button className="bulk-btn">
                    <Upload
                      beforeUpload={excelBeforeUpload}
                      showUploadList={false}
                      accept=".xlsx , .xls"
                      onChange={(info) => {
                        updateBulkVariations(info.file);
                      }}
                    >
                      UPDATE BULK VARIATIONS
                    </Upload>
                  </Button>
                ) : null}
                <Button
                  onClick={() => downloadAsExcel(toUpdateArray)}
                  className="bulk-btn"
                >
                  EXPORT VARIATIONS
                </Button>
                {!isManager ? (
                  <Button
                    onClick={() => setIsBulkValueModal(true)}
                    className="bulk-btn"
                  >
                    BULK VALUE UPDATE
                  </Button>
                ) : null}
                <Button className="new-var-btn">
                  <Upload
                    beforeUpload={excelBeforeUpload}
                    showUploadList={false}
                    accept=".xlsx , .xls"
                    onChange={(info) => {
                      handleFileUpload(info.file);
                    }}
                  >
                    ADD BULK VARIATIONS
                  </Upload>
                </Button>
                <Button
                  onClick={() => setIsAddVariantModal(true)}
                  className="new-var-btn"
                >
                  ADD NEW
                </Button>
              </div>
              <div className="table-section bottom-section new-product-table">
                <CommonTable
                  rowClassName={(record) =>
                    record.isDisable ? 'disabled-row' : null
                  }
                  columns={columns}
                  data={variations}
                  loadingData={loadingData}
                  rowKey={(record) => record.id}
                  paginationConfig={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
